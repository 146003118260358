import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';

import {QueryParamsService} from '../../core/services/query-params.service';
import {map} from 'rxjs/operators';


@Injectable()
export class FileRestService {

  constructor(
    public http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  getImage(imageUrl: string): Observable<File> {
    return from(fetch(imageUrl)
      .then((response) => {
        console.log(response);
        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
        return response.blob();
      })
      .then((blob) => {
        const imgParts = imageUrl.split('/').pop() || 'image.jpg';
        console.log(blob);
        return new File([blob], imgParts, { type: blob.type, lastModified: Date.now() });
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
        throw new Error("Failed to fetch image due to CORS issue or network error");
      }));
  }
}

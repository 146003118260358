import { Injectable } from '@angular/core';
import {MediaObjectRestService} from "../../../../api/services/media-object.rest.service";
import {MediaObjectViewModel} from "../../../../core/models/media-object.view-model";
import {QueryParamsApiModel} from "../../../../api/models/query-params-api.model";
import {PostApiModel} from "../../../../api/models/post-api.model";
import {ResultListApiModel} from "../../../../api/models/result-list.api.model";
import {MediaObjectApiModel} from "../../../../api/models/media-object.api-model";
import {map} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {FilterParameterApiModel} from "../../../../api/models/filter-parameter.api.model";


@Injectable({
  providedIn: 'root'
})
export class MediaObjectDataProviderService {
  public totalItems: number;
  public items: MediaObjectViewModel[];
  public page: number;
  public endOfResults: boolean;
  constructor(
    private mediaObjectRS: MediaObjectRestService,
  ) { }


  public getMediaObjectSubresourceCollection(resource: string, page: number, params: QueryParamsApiModel = new QueryParamsApiModel()): Observable<MediaObjectViewModel[]> {
    params.filters.push(new FilterParameterApiModel('limit', 9, 'single'));
    params.page = page;
    return this.mediaObjectRS.getMediaObjectSubresourceCollection(resource, params)
      .pipe(map((resultListAM: ResultListApiModel<MediaObjectApiModel>) => {
        return resultListAM.records.map((mediaObjectAM: MediaObjectApiModel) => new MediaObjectViewModel(mediaObjectAM));
      }));

    return of<MediaObjectViewModel[]>([]);
  }
}

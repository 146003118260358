import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LoaderService} from '../../../core/services/loader.service';
import {plainToClass} from 'class-transformer';
import {CredentialsApiModel} from '../../../api/models/credentials.api.model';
import {TokenDataProviderService} from '../../../core/services/token-data-provider.service';
import { HttpErrorResponse } from '@angular/common/http';
import {ResponseErrorsHandlerService} from '../../../core/services/response-errors-handler.service';
import {Capacitor} from '@capacitor/core';
import {FacebookService} from "../../../core/services/facebook.service";
import {AppTrackingTransparency} from "capacitor-plugin-app-tracking-transparency";
import {GoogleService} from "../../../core/services/google.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private ts: TokenDataProviderService,
    private ls: LoaderService,
    private reh: ResponseErrorsHandlerService,
    private fs: FacebookService,
    private gs: GoogleService
  ) {
    this.createForm();
  }

  public loginForm: UntypedFormGroup;
  public error: string;

  public readonly Capacitor = Capacitor;
  async ngOnInit() {

  }

  async ngAfterViewInit() {
  }


  currentStyles() {
    return {
      background: 'url(/uploads/backgrounds/test1.jpg) no-repeat fixed center',
      'background-size': 'cover',
    };
  }

  createForm() {
    this.loginForm = this.fb.group(
      {
        username: ['', Validators.required],
        password: ['', Validators.required],
        remember: [false],
      }
    );
  }

  login() {
    this.ls.show();
    this.ts.login(plainToClass(CredentialsApiModel, this.loginForm.value as object))
      .subscribe(response => {
        this.router.navigate(['/']);
      },
        (errorResponse: HttpErrorResponse) => {
        this.reh.handleServerErrors(errorResponse);
      }
    ).add(() => this.ls.hide());
  }

  async fbLogin() {
    await this.fs.initFacebook()
    let limited = false;

    if (Capacitor.getPlatform() === 'ios') {
      const response = await AppTrackingTransparency.getStatus();
      limited = response.status !== 'authorized';
    }

    this.ls.show();
    this.ts.fbLogin(limited)
      .subscribe(() => {
        this.router.navigate(['/']);
      },
        (errorResponse: HttpErrorResponse) => {
        console.log(errorResponse);
          this.reh.handleServerErrors(errorResponse);
        }
      ).add(() => this.ls.hide());
  }

  async googleLogin() {
    await this.gs.init()

    this.ls.show();
    this.ts.googleLogin()
      .subscribe(() => {
          this.router.navigate(['/']);
        },
        (errorResponse: HttpErrorResponse) => {
          console.log(errorResponse);
          this.reh.handleServerErrors(errorResponse);
        }
      ).add(() => this.ls.hide());
  }
}

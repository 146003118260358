import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {plainToClass, plainToClassFromExist} from 'class-transformer';
import {ResultListApiModel} from '../models/result-list.api.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {QueryParamsApiModel} from '../models/query-params-api.model';
import {QueryParamsService} from '../../core/services/query-params.service';
import {FollowApiModel} from "../models/follow.api-model";
import {ReactionApiModel} from "../models/reaction.api.model";
import {MarketplaceAdvertisementApiModel} from "../models/marketplace-advertisement.api.model";

@Injectable({
  providedIn: 'root'
})
export class MarketplaceAdvertisementRestService {
  private readonly resource: string = '/marketplace_advertisements';

  constructor(
    private http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public create(marketplaceAdvertisementAM: MarketplaceAdvertisementApiModel): Observable<MarketplaceAdvertisementApiModel> {
    return this.http.post<MarketplaceAdvertisementApiModel>(environment.apiUrl + this.resource, marketplaceAdvertisementAM)
      .pipe(map((result: MarketplaceAdvertisementApiModel) => plainToClass(MarketplaceAdvertisementApiModel, result)));
  }

  public put(marketplaceAdvertisementAM: MarketplaceAdvertisementApiModel): Observable<MarketplaceAdvertisementApiModel> {
    return this.http.put<MarketplaceAdvertisementApiModel>(environment.apiUrl + marketplaceAdvertisementAM['@id'], marketplaceAdvertisementAM)
      .pipe(map((result: MarketplaceAdvertisementApiModel) => plainToClass(MarketplaceAdvertisementApiModel, result)));
  }

  public get(slug: string): Observable<MarketplaceAdvertisementApiModel> {
    return this.http.get<MarketplaceAdvertisementApiModel>(environment.apiUrl + this.resource + '/' + slug)
      .pipe(map((result: MarketplaceAdvertisementApiModel) => plainToClass(MarketplaceAdvertisementApiModel, result)));
  }

  public delete(marketplaceAdvertisementAM: MarketplaceAdvertisementApiModel): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(environment.apiUrl + marketplaceAdvertisementAM['@id']);
  }

  public getCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<MarketplaceAdvertisementApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<MarketplaceAdvertisementApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<MarketplaceAdvertisementApiModel>(MarketplaceAdvertisementApiModel), result as object)));
  }

  public getUserSubResourceCollection(userId: string): Observable<ResultListApiModel<MarketplaceAdvertisementApiModel>> {
    return this.http.get<ResultListApiModel<MarketplaceAdvertisementApiModel>>(environment.apiUrl + userId + this.resource)
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<MarketplaceAdvertisementApiModel>(MarketplaceAdvertisementApiModel), result as object)));
  }

  public getFollowsSubResourceCollection(marketplaceAdvertisementAM: MarketplaceAdvertisementApiModel, queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<FollowApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<FollowApiModel>>(environment.apiUrl + marketplaceAdvertisementAM['@id'] + '/follows', {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<FollowApiModel>(FollowApiModel), result as object)));
  }

  public getReactionsSubResourceCollection(marketplaceAdvertisementAM: MarketplaceAdvertisementApiModel, queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<ReactionApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<ReactionApiModel>>(environment.apiUrl + marketplaceAdvertisementAM['@id'] + '/reactions', {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<ReactionApiModel>(ReactionApiModel), result as object)));
  }
}

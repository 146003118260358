import {Injectable} from '@angular/core';
import {ProjectRestService} from '../../../api/services/project.rest.service';
import {forkJoin, Observable, of} from 'rxjs';
import {ProjectViewModel} from './project.view-model';
import {ResultListApiModel} from '../../../api/models/result-list.api.model';
import {ProjectApiModel} from '../../../api/models/project.api-model';
import {map, mergeMap, tap} from 'rxjs/operators';
import {StatusApiModel} from '../../../api/models/status.api.model';
import {StatusViewModel} from '../../../core/models/status.view-model';
import {VehicleRestService} from '../../../api/services/vehicle.rest.service';
import {VehicleTypeApiModel} from '../../../api/models/vehicle-type.api-model';
import {VehicleViewModel} from './vehicle.view-model';
import {VehicleApiModel} from '../../../api/models/vehicle.api-model';
import {VehicleTypeViewModel} from '../../../core/models/vehicle-type.view-model';
import {QueryParamsApiModel} from '../../../api/models/query-params-api.model';
import {FilterParameterApiModel} from '../../../api/models/filter-parameter.api.model';
import {GarageStatusEnum} from './garage-status.enum';
import {VehicleTypeRestService} from '../../../api/services/vehicle-type.rest.service';
import {CurrentUserViewModel} from '../../../core/models/current-user.view-model';
import {UserViewModel} from '../settings/user.view-model';
import {MediaObjectRestService} from '../../../api/services/media-object.rest.service';
import {MediaObjectViewModel} from '../../../core/models/media-object.view-model';
import {MediaObjectApiModel} from '../../../api/models/media-object.api-model';
import {ListingTileDataInterface} from '../../../shared/listing-tile/listing-tile-data.interface';

@Injectable()
export class GarageDataProviderService {
  public vehicleTypes: VehicleTypeViewModel[];
  public projects: ProjectViewModel[];
  public filteredProjects: ProjectViewModel[] = [];
  public statuses: StatusViewModel[];

  constructor(
    private projectRS: ProjectRestService,
    private vehicleRS: VehicleRestService,
    private vehicleTypeRS: VehicleTypeRestService,
    private mediaObjectRS: MediaObjectRestService
  ) {
  }

  public createProject(currentUserVM: CurrentUserViewModel, vehicleVM: VehicleViewModel): Observable<ProjectViewModel> {
    const projectVM = ProjectViewModel.create(currentUserVM, vehicleVM);
    return this.projectRS.create(projectVM.toApiModel())
      .pipe(map((projectAM: ProjectApiModel) => {
        const projectVM = new ProjectViewModel(projectAM);
        projectVM.vehicle = vehicleVM;
        projectVM.user = currentUserVM;
        this.projects.push(projectVM);
        return projectVM;
      }));
  }

  public updateProject(projectVM: ProjectViewModel): Observable<ProjectViewModel> {
    return this.projectRS.put(projectVM.toApiModel())
      .pipe(map(() => {
        return projectVM;
      }));
  }

  public fetchResources(user: UserViewModel): Observable<ProjectViewModel[]> {
    return this.getUserSubResourceCollection(user)
      .pipe(tap((projectVMs: ProjectViewModel[]) => {
        this.projects = projectVMs;
        this.filteredProjects = projectVMs;
      }));
  }

  public getCollection(): Observable<ProjectViewModel[]> {
    return this.projectRS.getCollection().pipe(map((resultListAM: ResultListApiModel<ProjectApiModel>) => {
      return resultListAM.records.map((projectAM: ProjectApiModel) => new ProjectViewModel(projectAM));
    }));
  }

  public getUserSubResourceCollection(user: UserViewModel): Observable<ProjectViewModel[]> {
    return this.projectRS.getUserSubResourceCollection(user['@id']).pipe(mergeMap((resultListAM: ResultListApiModel<ProjectApiModel>) => {
      const deps = forkJoin([
        this.getVehicleCollection(resultListAM.records),
        this.getMediaObjectCollection(resultListAM.records)
      ]);
      return deps.pipe(map((
        [vehicleVMs, mediaObjectMVs]:
          [VehicleViewModel[], MediaObjectViewModel[]]) => {
        return resultListAM.records.map((projectAM: ProjectApiModel) => {
          const projectVM = new ProjectViewModel(projectAM);
          projectVM.vehicle = vehicleVMs.find(e => e['@id'] === projectAM.vehicle);
          projectVM.user = user;
          projectVM.theme = mediaObjectMVs.find(e => e['@id'] === projectAM.theme);
          return projectVM;
        });
      }));
    }));
  }

  public getVehicleCollection(projectAMs: ProjectApiModel[]): Observable<VehicleViewModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = projectAMs.map(e => new FilterParameterApiModel('id', e.vehicle));
    return this.vehicleRS.getVehicleCollection(params)
      .pipe(mergeMap((resultListAM: ResultListApiModel<VehicleApiModel>) => {
        return this.getVehicleTypeCollection().pipe(map((vehicleTypeVMs: VehicleTypeViewModel[]) => {
          this.vehicleTypes = vehicleTypeVMs;
          return resultListAM.records.map((vehicleAM: VehicleApiModel) => {
            const vehicleVM = new VehicleViewModel(vehicleAM);
            vehicleVM.vehicleType = vehicleTypeVMs.find(e => e['@id'] === vehicleAM.vehicleType);
            return vehicleVM;
          });
        }));
      }));
  }

  public getVehicleTypeCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<VehicleTypeViewModel[]> {
    return this.vehicleTypeRS.getVehicleTypeCollection(queryParamsApiModel)
      .pipe(map((resultListAM: ResultListApiModel<VehicleTypeApiModel>) => {
        return resultListAM.records.map((vehicleAM: VehicleTypeApiModel) => new VehicleTypeViewModel(vehicleAM));
      }));
  }

  public getMediaObjectCollection(projectAMs: ProjectApiModel[]): Observable<MediaObjectViewModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = projectAMs
      .filter((e: ProjectApiModel) => typeof e.theme === 'string')
      .map(e => new FilterParameterApiModel('id', e.theme));
    if (params.filters.length) {
      return this.mediaObjectRS.getMediaObjectCollection(params)
        .pipe(map((resultListAM: ResultListApiModel<MediaObjectApiModel>) => {
          return resultListAM.records.map((mediaObjectAM: MediaObjectApiModel) => new MediaObjectViewModel(mediaObjectAM));
        }));
    }

    return of<MediaObjectViewModel[]>([]);
  }

  public uploadProjectTheme(listingTileTI: ListingTileDataInterface): Observable<ProjectViewModel> {
    return this.mediaObjectRS.uploadImage(listingTileTI.theme)
      .pipe(
        mergeMap((mediaObjectAM: MediaObjectApiModel) => {
          const projectAM = listingTileTI.refObject.toApiModel();
          projectAM.theme = mediaObjectAM['@id'];
          return this.projectRS.put(projectAM).pipe(map((projectAM: ProjectApiModel) => {
            const projectVM = listingTileTI.refObject.fromApiModel(projectAM);
            projectVM.theme = new MediaObjectViewModel(mediaObjectAM);
            return projectVM;
          }));
        })
      );
  }

  public removeProjectTheme(listingTileTI: ListingTileDataInterface): Observable<ProjectViewModel> {
    const projectAM = listingTileTI.refObject.toApiModel();
    return this.mediaObjectRS.deleteMediaObject(projectAM.theme)
      .pipe(
        map(() => {
            projectAM.theme = null;
            listingTileTI.refObject.theme = null;
            return listingTileTI.refObject;
        })
      );
  }

  public getStatusCollection(): Observable<StatusViewModel[]> {
    return this.projectRS.getStatusCollection()
      .pipe(map((resultListAM: ResultListApiModel<StatusApiModel>) => {
        return resultListAM.records.map((statusAM: StatusApiModel) => new StatusViewModel(statusAM));
      }));
  }

  public filterProjectListByStatus(status: string): ProjectViewModel[] {
    if (status === GarageStatusEnum.ALL) {
      return this.filteredProjects;
    }
    return this.filteredProjects.filter((project: ProjectViewModel) => {
      return project.status === status;
    });
  }

  public filterByINDIVIDUALStatus(): ProjectViewModel[] {
    return this.filterProjectListByStatus(GarageStatusEnum.INDIVIDUAL);
  }

  public filterByProjectSaleStatus(): ProjectViewModel[] {
    return this.filterProjectListByStatus(GarageStatusEnum.ON_SALE);
  }

  public filterByProjectCollaborationStatus(): ProjectViewModel[] {
    return this.filterProjectListByStatus(GarageStatusEnum.COLLABORATION);
  }

  public filterByProjectArchivedStatus(): ProjectViewModel[] {
    return this.filterProjectListByStatus(GarageStatusEnum.SOLD);
  }

  public onItemRemove(removedItem: ProjectViewModel) {
    const projectIndex = this.projects.indexOf(removedItem);
    this.projects.splice(projectIndex, 1);
  }

  public filterListByVehicleType(id): void {
    if (id === '') {
      this.filteredProjects = this.projects;
      return;
    }
    this.filteredProjects = this.projects.filter((e: ProjectViewModel) => {
      return e.vehicle.vehicleType.id === id;
    });
  }
}

import {PasswordApiModel} from '../../api/models/password.api-model';
import {UserApiModel} from '../../api/models/user.api-model';
import {MediaObjectViewModel} from './media-object.view-model';
import {UserViewModel} from '../../features/user/settings/user.view-model';

export class CurrentUserViewModel extends UserViewModel {
  public id: number;
  public username: string;
  public email: string;
  public password: PasswordApiModel;
  public slug: string;
  public avatar: MediaObjectViewModel;
  public background: MediaObjectViewModel;
  public images: string[];
  public info: string;
  public roles: string[];
  public currentPassword: string;

  public constructor(public apiModel: UserApiModel) {
    super(apiModel);
    this.fromApiModel(apiModel);
  }

  fromApiModel(apiModel: UserApiModel): this {
    this['@id'] = apiModel['@id'];
    this.id = this.apiModel.id;
    this.username = this.apiModel.username;
    this.email = this.apiModel.email;
    this.slug = this.apiModel.slug;
    this.images = this.apiModel.images;
    this.info = this.apiModel.info as string;
    this.roles = this.apiModel.roles;
    return this;
  }

  toApiModel(): UserApiModel {
    this.apiModel['@id'] = this.apiModel['@id'] || this['@id'];
    this.apiModel.id = this.id;
    this.apiModel.username = this.username;
    this.apiModel.email = this.email;
    this.apiModel.slug = this.slug;
    this.apiModel.images = this.images;
    this.apiModel.info = this.info;
    return this.apiModel;
  }
}

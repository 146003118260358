<div class="border-1 surface-border border-round mb-4">
  <span
    class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'shared.modal.modal_service.add_event.media'|translate }}</span>
  <p-fileUpload #fileUploader
                accept="image/*"
                [styleClass]="'multi-upload'"
                (onUpload)="onUpload($event, 'multiple')"
                [customUpload]="true"
                (onSelect)="onUpload($event, 'multiple')"
                [showUploadButton]="false"
                [showCancelButton]="false"
                [previewWidth]="128"
                [auto]="true"
                [multiple]="true"
                (uploadHandler)="onFileSelect($event)"
                (onRemove)="onFileRemove($event)"
                [classList]="'gallery-upload'"
                styleClass="border-1 surface-border surface-card p-0 border-round mb-4">
    <ng-template pTemplate="content">
      <div class="h-10rem p-2 m-1 border-round">
        <div
          class="grid grid-nogutter flex gap-2 w-full h-full justify-content-center align-items-center">
          <ng-container *ngIf="mediaChoiceOpened">
            <div
              pRipple
              (click)="fileUploader.advancedFileInput.nativeElement.click()"
              class="col flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer border-1 surface-border border-round"
            >
              <i class="pi pi-fw pi-plus text-4xl text-primary"></i>
              <span class="block font-semibold text-900 text-lg mt-3">{{ 'dodaj obrazy'|translate }}</span>
            </div>
            <div
              pRipple
              (click)="openMediaChoiceModal()"
              class="col flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer border-1 surface-border border-round"
            >
              <i class="pi pi-fw pi-table text-4xl text-primary"></i>
              <span class="block font-semibold text-900 text-lg mt-3">{{ 'wybierz z galerii'|translate }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="!mediaChoiceOpened">
            <div
              pRipple
              class="col flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
              (click)="mediaChoiceOpened = true"
            >
              <i class="pi pi-fw pi-images text-4xl text-primary"></i>
              <span
                class="block font-semibold text-900 text-lg mt-3">{{ 'shared.modal.modal_service.add_event.media_desc'|translate }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </p-fileUpload>
</div>
<p-dialog [appendTo]="'body'" header="{{ 'Wybierz z galerii'|translate }}" [modal]="true" [(visible)]="mediaDialogOpened" [styleClass]="'modal-media-maximized'" [draggable]="false" >
  <ng-template pTemplate="content">
    <app-media-choice *ngIf="mediaDialogOpened && projectVM" [projectVM]="projectVM" [selectedMedia]="mediaObjectAMs"></app-media-choice>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-between">
      <button pButton pRipple type="button" label="Resetuj" class="p-button-text p-button-danger" (click)="reset()"></button>
      <button pButton pRipple type="button" label="Zaznacz wszystko" class="p-button-text p-button-plain" (click)="selectAll()"></button>
      <button pButton pRipple type="button" label="Wybierz" class="p-button-text" (click)="selectMedia(fileUploader)"></button>
    </div>
  </ng-template>
</p-dialog>


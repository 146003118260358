import {SocialLogin} from "@capgo/capacitor-social-login";

export class AbstractOauthService {
  public static logout(): void {
    (async () => {
      for (const e  of ['google', 'facebook']) {
        let {isLoggedIn} = await SocialLogin.isLoggedIn({provider: e as 'google'|'facebook'})
        if (isLoggedIn) {
          await SocialLogin.logout({provider: e as 'google'|'facebook'});
        }
      }
    })();
  }
}

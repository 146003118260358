<form [formGroup]="form">
  <div class="grid m-0 p-1" style="width: 100%">
    <div class="col-12 lg:col-7">
      <div class="border-1 surface-border border-round mb-4">
        <span class="text-900 font-bold block border-bottom-1 surface-border p-3">{{'shared.modal.modal_service.add_event.cover'|translate}}</span>
        <p-fileUpload #coverUploader
                      accept="image/*"
                      [styleClass]="'single-upload'"
                      (onUpload)="onUpload($event, 'single')"
                      [customUpload]="true"
                      (onSelect)="onUpload($event, 'single')"
                      [showUploadButton]="false"
                      [showCancelButton]="false"
                      [previewWidth]="128"
                      [multiple]="false"
                      [auto]="false" styleClass="border-1 surface-border surface-card p-0 border-round mb-4">
          <ng-template pTemplate="content">
            <div class="h-20rem m-1 border-round">
              <div *ngIf="!image" class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer" (click)="coverUploader.advancedFileInput.nativeElement.click()">
                <i class="pi pi-fw pi-image text-4xl text-primary"></i>
                <span class="block font-semibold text-900 text-lg mt-3">{{'shared.modal.modal_service.add_event.cover_desc'|translate}}</span>
              </div>
              <div *ngIf="image" class="w-full h-full relative border-round p-0">
                <img [src]="image.objectURL" class="w-full h-full border-round" style="object-fit:contain" alt="event cover">
                <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-primary text-sm absolute justify-content-center align-items-center" style="top: -10px; right: -10px;" (click)="$event.stopPropagation(); removeImage()"></button>
              </div>
            </div>
            <input type="hidden" formControlName="file">
            <div *ngIf="form.get('file').invalid" class="app-form-error">
              <p>{{form.get('file')['errorMessage']}}</p>
              <p *ngIf="form.get('file').errors?.custom">{{form.get('file').errors.custom}}</p>
            </div>
          </ng-template>
        </p-fileUpload>
      </div>

      <div class="border-1 surface-border border-round mb-4">
        <span class="text-900 font-bold block border-bottom-1 surface-border p-3">{{'shared.modal.modal_service.add_event.content'|translate}}</span>
        <div class="flex flex-column p-fluid p-2">
          <div class="mb-4 p-field p-float-label">
            <input type="text" formControlName="name" pInputText/>
            <label>{{'shared.modal.modal_service.add_event.name'|translate}} <span class="text-danger">*</span></label>
            <div *ngIf="form.get('name').invalid && form.get('name').touched" class="app-form-error">
              <p>{{form.get('name')['errorMessage']}}</p>
              <p *ngIf="form.get('name').errors?.custom">{{form.get('name').errors.custom}}</p>
            </div>
          </div>
          <div class="mb-4 p-float-label">
            <p-editor
              [placeholder]="'shared.modal.modal_service.add_event.describe_event'|translate"
              [formControlName]="'description'"
              [style]="{'height':'200px'}"
            ></p-editor>
          </div>
        </div>
      </div>

      <div class="border-1 surface-border border-round mb-4">
        <span class="text-900 font-bold block border-bottom-1 surface-border p-3">{{'shared.modal.modal_service.add_event.media'|translate}}</span>
        <p-fileUpload #fileUploader
                      accept="image/*"
                      [styleClass]="'multi-upload'"
                      (onUpload)="onUpload($event, 'multiple')"
                      [customUpload]="true"
                      (onSelect)="onUpload($event, 'multiple')"
                      [showUploadButton]="false"
                      [showCancelButton]="false"
                      [previewWidth]="128"
                      [auto]="true"
                      [multiple]="true"
                      (uploadHandler)="onFileSelect($event)"
                      (onRemove)="onFileRemove($event)"
                      [classList]="'gallery-upload'"
                      styleClass="border-1 surface-border surface-card p-0 border-round mb-4">
          <ng-template pTemplate="content">
            <div class="h-10rem m-1 border-round">
              <div
                class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                (click)="fileUploader.advancedFileInput.nativeElement.click()">
                <i class="pi pi-fw pi-images text-4xl text-primary"></i>
                <span class="block font-semibold text-900 text-lg mt-3">{{'shared.modal.modal_service.add_event.media_desc'|translate}}</span>
              </div>
            </div>
          </ng-template>
        </p-fileUpload>
      </div>

      <div class="border-1 surface-border border-round mb-4">
        <span class="text-900 font-bold block border-bottom-1 surface-border p-3">{{'shared.modal.modal_service.add_event.date'|translate}}</span>
        <div class="flex flex-column p-fluid py-2 px-1">
          <ng-container formArrayName="eventDates">
            <div *ngFor="let eventDate of eventDates.controls; index as i">
              <ng-container [formGroupName]="i">
                <div class="grid p-2">
                  <div class="col-12 lg:col-3 p-1">
                    <div class="p-float-label">
                      <input [id]="'name-{{i}}'" pInputText formControlName="name" />
                      <label [for]="'name-{{i}}'">{{'shared.modal.modal_service.add_event.day_name' | translate}}</label>
                    </div>
                    <small>{{'shared.modal.modal_service.add_event.day_name_ex'|translate: {number: i + 1} }}</small>
                  </div>
                  <div class="col-12 lg:col-4 p-1">
                    <div class="p-float-label">
                      <p-calendar inputId="date"
                                  [formControlName]="'date'"
                                  [showIcon]="true"
                      ></p-calendar>
                      <label [for]="'date'">{{'shared.modal.modal_service.add_event.day_date' | translate}}&nbsp;<span class="text-danger">*</span></label>
                    </div>
                  </div>
                  <div class="col-12 lg:col-4 p-1">
                    <div class="p-inputgroup grid-nogutter">
                      <div class="p-inputgroup-addon">
                          <i class="pi pi-clock"></i>
                      </div>
                      <div class="col p-float-label">
                        <p-calendar inputId="from"
                                    [formControlName]="'from'"
                                    [showTime]="true"
                                    [showSeconds]="false"
                                    [showIcon]="false"
                                    [timeOnly]="true"
                                    [icon]="'pi pi-clock'"
                        ></p-calendar>
                        <label [for]="'from'">{{'shared.modal.modal_service.add_event.hour_from'  | translate}}&nbsp;<span class="text-danger">*</span></label>
                      </div>
                      <div class="col p-float-label">
                        <p-calendar inputId="to"
                                    [formControlName]="'to'"
                                    [showTime]="true"
                                    [showSeconds]="false"
                                    [showIcon]="false"
                                    [timeOnly]="true"
                                    [icon]="'pi pi-clock'"
                        >
                          >
                        </p-calendar>
                        <label [for]="'to'">{{'shared.modal.modal_service.add_event.hour_to'| translate}}&nbsp;<span class="text-danger">*</span></label>
                      </div>
                    </div>
                  </div>
<!--                  <div class="col-12 lg:col-2 p-1 p-float-label">-->

<!--                  </div>-->
                  <div class="col-12 lg:col-1 p-1 flex justify-content-center">
                    <button pButton
                            pRipple
                            [disabled]="i == 0"
                            [style.visibility]="i == 0 ? 'hidden' : 'visible'"
                            class="p-button-rounded p-button-text"
                            icon="pi pi-trash"
                            (click)="removeDate()">
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <div class="flex p-2 justify-content-center">
            <button pButton pRipple type="button" class="p-button-icon-only p-button-rounded p-button-text" icon="pi pi-plus" (click)="addDate()"></button>
          </div>
        </div>
      </div>
      <div class="border-1 surface-border border-round mb-4">
        <span class="text-900 font-bold block border-bottom-1 surface-border p-3">{{'shared.modal.modal_service.add_event.location'|translate}}&nbsp;<span class="text-danger">*</span></span>
        <div class="grid p-fluid p-2">
          <div class="col-12 mb-2 ">
            <p-autoComplete
              formControlName="address"
              [placeholder]="'shared.modal.modal_service.add_event.search'| translate"
              [suggestions]="searchResults"
              (completeMethod)="searchPlaces($event)"
              (onSelect)="selectPlace($event)"
              [minLength]="2"
              [delay]="500"
              [optionLabel]="'label'"
              [optionValue]="getAddrOptionValue"
              [forceSelection]="true"
              [required]="true"
            >
              <ng-template let-result pTemplate="item">
                <div class="text-white">{{ result.label }}</div>
              </ng-template>
            </p-autoComplete>
            <div *ngIf="form.get('address').invalid && form.get('address').touched" class="app-form-error">
              <p>{{form.get('address')['errorMessage']}}</p>
              <p *ngIf="form.get('address').errors?.custom">{{form.get('address').errors.custom}}</p>
            </div>
          </div>
          <div class="col-12">
            <app-map></app-map>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-5">
      <div class="border-1 surface-border border-round mb-4">
        <span class="text-900 font-bold block border-bottom-1 surface-border p-3">{{'shared.modal.modal_service.add_event.publish_options'|translate}}</span>
        <div class="p-2">
          <div class="surface-100 p-3 flex align-items-center border-round">
            <span class="text-900 font-semibold mr-3">{{'shared.modal.modal_service.add_event.status'|translate}}:</span>
            <span class="font-medium">{{'shared.modal.modal_service.add_event.draft'|translate}}</span>
            <button pButton pRipple type="button" icon="pi pi-fw pi-pencil"
                    class="p-button-rounded p-button-text ml-auto"></button>
          </div>
        </div>
        <div class="p-2">
          <div class="surface-100 p-3 flex align-items-center border-round">
            <span class="text-900 font-semibold mr-3">{{'shared.modal.modal_service.add_event.visibility'|translate}}:</span>
            <span class="font-medium">{{'shared.modal.modal_service.add_event.private'|translate}}</span>
            <button pButton pRipple type="button" icon="pi pi-fw pi-pencil"
                    class="p-button-rounded p-button-text ml-auto"></button>
          </div>
        </div>
      </div>
      <div class="border-1 surface-border border-round mb-4">
        <span class="text-900 font-bold block border-bottom-1 surface-border p-3">{{'shared.modal.modal_service.add_event.organizers'|translate}}&nbsp;<span class="text-danger">*</span></span>
        <div class="grid p-fluid p-2">
          <div class="col-12 mb-2">
            <p-autoComplete
              formControlName="organizers"
              [placeholder]="'shared.modal.modal_service.add_event.search'| translate"
              [suggestions]="eventPS.resources"
              (completeMethod)="emitQuery($event)"
              [minLength]="2"
              [delay]="500"
              [field]="'label'"
              (onSelect)="addOrganizer($event)"
            >
              <ng-template let-result pTemplate="item">
                <div class="text-white">{{ result.alias }}</div>
              </ng-template>
            </p-autoComplete>
            <div *ngIf="form.get('organizers').invalid && form.get('organizers').touched" class="app-form-error">
              <p>{{form.get('organizers')['errorMessage']}}</p>
              <p *ngIf="form.get('organizers').errors?.custom">{{form.get('organizers').errors.custom}}</p>
            </div>
          </div>
          <div class="col-12 flex flex-wrap gap-2" style="column-gap: 3px">
            <p-chip
              *ngFor="let organizer of organizers; let i = index;"
              [label]="organizer.alias" [image]="organizer.avatar"
              [removable]="i!==0"
              (onRemove)="removeOrganizer($event)"
            ></p-chip>
          </div>
        </div>
      </div>
      <div class="border-1 surface-border border-round mb-4">
        <span class="text-900 font-bold block border-bottom-1 surface-border p-3">{{'shared.modal.modal_service.add_event.tags'|translate}}</span>
        <div class="p-2">
          <app-hashtags formControlName="hashtags" [placeholder]="'shared.modal.modal_service.add_event.search_or_add'| translate">
            <div *ngIf="form.get('hashtags').invalid && form.get('hashtags').touched" class="app-form-error">
              <p>{{form.get('hashtags')['errorMessage']}}</p>
              <p *ngIf="form.get('hashtags').errors?.custom">{{form.get('hashtags').errors.custom}}</p>
            </div>
          </app-hashtags>
        </div>
      </div>
      <div class="border-1 surface-border border-round p-fluid mb-4">
        <span class="text-900 font-bold block border-bottom-1 surface-border p-3">{{'shared.modal.modal_service.add_event.meta'|translate}}</span>
        <div class="p-2">
          <div class="my-4">
            <p-inputSwitch styleClass="mx-1" (onChange)="toggleCopyValuesToMetaTags($event)"></p-inputSwitch>
            <span class="pl-1">{{'shared.modal.modal_service.add_event.copy_values'|translate}}</span>
          </div>
          <div class="mb-4">
            <input type="text" formControlName="metaTitle" pInputText placeholder="{{'shared.modal.modal_service.add_event.meta_title'|translate}}"/>
          </div>
          <div class="mb-4">
            <input type="text" formControlName="metaKeywords" pInputText placeholder="{{'shared.modal.modal_service.add_event.meta_keywords'|translate}}"/>
          </div>
          <div>
            <textarea rows="6" formControlName="metaDescription" pInputTextarea placeholder="{{'shared.modal.modal_service.add_event.meta_description'|translate}}"
                      [autoResize]="true"></textarea>
          </div>
        </div>

      </div>
      <p><span class="text-danger">*</span>&nbsp;{{'common.required_fields'|translate}}</p>
    </div>
  </div>
</form>



import {AfterViewInit, Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ModalInterface} from '../../modal.interface';
import {defer, forkJoin, merge, Observable, of, Subject} from 'rxjs';
import {ModalStateEvent} from '../../modal-state-event';
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {ModalState} from '../../modal-state.enum';
import {MediaObjectRestService} from '../../../../api/services/media-object.rest.service';
import {MediaObjectApiModel} from '../../../../api/models/media-object.api-model';
import {finalize, ignoreElements, map, mergeMap, tap} from 'rxjs/operators';
import {EventApiModel} from '../../../../api/models/event.api.model';
import {EventViewModel} from './event.view-model';
import {AddEventProviderService} from './add-event.provider.service';
import {SelectItem} from 'primeng/api';
import {MediaObjectViewModel} from '../../../../core/models/media-object.view-model';
import {add, roundToNearestMinutes} from 'date-fns';
import {FilesUploadEventInterface} from '../../../interfaces/files-upload-event.interface';
import {FileRemoveEventInterface} from '../../../interfaces/file-remove-event.interface';
import {PostApiModel} from '../../../../api/models/post-api.model';
import {PostTypeEnum} from '../../../../core/enums/post-type.enum';
import {PostRestService} from '../../../../api/services/post-rest.service';
import {MapComponent} from '../../../map/map.component';
import {MapService} from '../../../map/map.service';
import {UserViewModel} from '../../../../features/user/settings/user.view-model';
import {OrganizerInterface} from './organizer.interface';
import {
  PersonalInformationViewModel
} from '../../../../features/user/settings/personal-information/personal-information-view-model';
import {TokenDataProviderService} from '../../../../core/services/token-data-provider.service';
import {CurrentUserViewModel} from '../../../../core/models/current-user.view-model';
import {LoaderService} from '../../../../core/services/loader.service';
import {ResponseErrorsHandlerService} from '../../../../core/services/response-errors-handler.service';
import {RemoveHtmlTagsPipe} from '../../../../core/pipes/remove-html-tags.pipe';
import {TranslateService} from "@ngx-translate/core";
import {HashtagValidator} from "../../../../core/validators/hashtag-validator";
import {AutoCompleteSelectEvent} from "primeng/autocomplete";

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss'],
  providers: [AddEventProviderService]
})
export class AddEventComponent implements ModalInterface, OnInit, AfterViewInit {
  get searchText(): string {
    return this.form.get('address').value;
  }

  get eventDates(): UntypedFormArray {
    if (this.form) {
      return this.form.get('eventDates') as UntypedFormArray;
    }
  }

  public data: any;
  public subject: Subject<ModalStateEvent>;
  public state: Observable<ModalStateEvent>;
  public form: UntypedFormGroup = this.createForm();
  public image: any;
  public objectURL = '';
  public organizers: any[] = [];
  public options: any;
  private file: File;
  public eventVM: EventViewModel;
  searchResults: SelectItem[] = [];
  searchQuery: string;
  public files: File[] = [];
  public disabled = false;
  public mediaProgress?: number;

  public mediaObjectAMs: MediaObjectApiModel[] = [];
  @ViewChild(MapComponent) public mapComponent: MapComponent;

  // @ViewChild(GMap) gmap: GMap;
  constructor(
    private readonly fb: RxFormBuilder,
    private readonly renderer: Renderer2,
    private readonly mediaObjectRS: MediaObjectRestService,
    public readonly eventPS: AddEventProviderService,
    private readonly postRS: PostRestService,
    private readonly mapService: MapService,
    private readonly tokenDP: TokenDataProviderService,
    private readonly ls: LoaderService,
    private readonly reh: ResponseErrorsHandlerService,
    private readonly translator: TranslateService
  ) {
  }

  public ngOnInit() {
    this.eventVM = this.data.eventVM;

    this.state.subscribe((mse: ModalStateEvent) => {
      switch (mse.status) {
        case ModalState.PENDING:
          this.submit();
      }
    });

    this.tokenDP.currentUser.subscribe((user: CurrentUserViewModel) => {
      this.organizers.push(this.createOrganizer(user));
    });

    this.eventPS.fetchResourcesFromQuerySubject().subscribe(() => {
    });
  }

  private createForm() {
    const namesValidators = [
      RxwebValidators.required(),
      RxwebValidators.minLength({value: 2}),
      RxwebValidators.maxLength({value: 255})
    ];
    const searchValidators = [
      RxwebValidators.required(),
      RxwebValidators.minLength({value: 2}),
    ];
    const form = this.fb.group({
      file: [null],
      name: [null, namesValidators],
      description: ['', [RxwebValidators.minLength({value: 2}), RxwebValidators.maxLength({value: 65000})]],
      address: [null, searchValidators],
      eventDates: new UntypedFormArray([this.createEventDateGroup()], [RxwebValidators.choice({minLength: 1})]),
      organizers: [''],
      hashtags: [null, [HashtagValidator.maxHashtags(10, this.translator), HashtagValidator.uniqueHashtags(this.translator)]],
      metaTitle: [null],
      metaKeywords: [null],
      metaDescription: [null],
    });

    form.valueChanges.subscribe(() => {
      if (form.valid) {
        this.subject.next(new ModalStateEvent(ModalState.VALID));
      } else {
        this.subject.next(new ModalStateEvent(ModalState.INVALID));
      }
    });

    return form;
  }

  ngAfterViewInit(): void {
  }


  createEventDateGroup(): UntypedFormGroup {
    const timeStart = roundToNearestMinutes(new Date(), {nearestTo: 15});
    return this.fb.group({
      name: new UntypedFormControl('', [
        RxwebValidators.minLength({value: 2}),
        RxwebValidators.maxLength({value: 255}),
      ]),
      date: new UntypedFormControl(add(timeStart, {days: 1}), [RxwebValidators.date()]),
      from: new UntypedFormControl(timeStart, [RxwebValidators.date()]),
      to: new UntypedFormControl(add(timeStart, {hours: 1}), [RxwebValidators.date()])
    });
  }

  public toggleCopyValuesToMetaTags(event: { checked: boolean }): void {
    if (event.checked) {
      this.copyValue(this.form, 'name', 'metaTitle');
      this.copyValue(this.form, 'description', 'metaDescription');
    } else {
      this.form.get('metaTitle').reset();
      this.form.get('metaKeywords').reset();
      this.form.get('metaDescription').reset();
    }
  }

  public copyValue(form: UntypedFormGroup, copyFromFieldName: string, subscribingFormFieldName: string): void {
      const field = form.get(subscribingFormFieldName);
      field.setValue(RemoveHtmlTagsPipe.sanitize(form.get(copyFromFieldName).value));
  }

  addDate(): void {
    const group = this.createEventDateGroup();
    this.eventDates.push(group);
  }

  removeDate(): void {
    if (this.eventDates.length > 1) {
      this.eventDates.removeAt(this.eventDates.length - 1);
    }
  }

  onUpload(event: any, type: 'single' | 'multiple') {
    this.openMediaBox();
    switch (type) {
      case 'single':
        const file = event.files[0];
        file.objectURL = file.objectURL ? file.objectURL : this.objectURL;
        if (!file.objectURL) {
          return;
        } else {
          this.image = file;
          this.objectURL = file.objectURL;
        }
        break;
      case 'multiple':
        // this.form.get('images').setValue(event.files);
        break;
    }

  }

  removeImage() {
    this.image = null;
  }

  public openMediaBox(): void {
    setTimeout(() => {
      const elements = document.querySelectorAll('.p-fileupload-row button');
      elements.forEach((e) => {
        this.renderer.addClass(e, 'p-button-text');
      });
    }, 1);
  }

  addMedia(files: File[]): Observable<any> {
    this.disabled = true;
    const requests: Observable<PostApiModel>[] = [];
    files.forEach((file: File) => {
      if (!this.mediaObjectAMs.find((e: MediaObjectApiModel) => {
        return e.name === file.name;
      })) {
        const req = this.postMediaObject(file).pipe(mergeMap((mediaObjectAM: MediaObjectApiModel) => {
          const postAM = new PostApiModel();
          postAM.media = [];
          postAM.media.push(mediaObjectAM['@id']);
          postAM.event = this.eventVM['@id'];
          postAM.type = PostTypeEnum.EVENT_MEDIA_POST;
          postAM.content = '';
          return this.postRS.create(postAM);
        }));
        requests.push(req);
      }
    });

    if (!requests.length) {
      return of([]);
    }

    return defer(() => {
      let counter = 0;
      const percent$ = new Subject();

      const modifiedObservablesList = requests.map(
        (item, index) => item.pipe(
          finalize(() => {
            const percentValue = ++counter * 100 / requests.length;
            percent$.next(percentValue);
          })
        )
      );

      const finalResult$ = forkJoin(modifiedObservablesList).pipe(
        tap(() => {
            percent$.next(100);
            percent$.complete();
          }
        ));

      return of([finalResult$, percent$.asObservable()]);
    }).pipe(
      mergeMap(([finalResult, progress]) => merge(
        progress.pipe(
          tap((value: number) => this.mediaProgress = value),
          ignoreElements()
        ),
        finalResult
      ))
    );
  }

  public submit(): void {
    let req;
    if (this.form.valid) {
      if (!this.eventVM) {
        this.eventVM = this.form.value;
        this.eventVM.apiModel = new EventApiModel();
        this.eventVM.organizers = this.form.get('organizers').value.length ? this.form.get('organizers').value : [];
        this.eventVM.hashtags = this.form.get('hashtags').value.length ? this.form.get('hashtags').value : [];
        req = forkJoin([
          this.image ? this.mediaObjectRS.uploadImage(this.image) : of(null),
          this.files ? this.addMedia(this.files) : of([]),
        ])
          .pipe(
            mergeMap(([mediaObjectAM, postAMs]: [MediaObjectApiModel, PostApiModel[]]) => {
              this.eventVM.cover = mediaObjectAM ? new MediaObjectViewModel(mediaObjectAM) : null;
              this.eventVM.media = postAMs.map((postAM: PostApiModel) => postAM.media[0]);
              this.eventVM.posts = postAMs;
              this.closeMediaLoadProcess();
              return this.eventPS.createEvent(this.eventVM).pipe(map((eventAM: EventApiModel) => {
                return eventAM;
              }));
            })
          );
      } else {
        this.eventVM = {...this.eventVM.apiModel, ...this.form.value};
        // req = this.eventPS.updateHistoryPost(this.eventVM);
      }

      req.subscribe((eventAM: EventApiModel) => {
        this.subject.next(new ModalStateEvent(ModalState.SUCCESS, eventAM));

        this.form.reset({
          heading: null,
          dateRange: null,
          content: null,
        });
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();
      }, (errorResponse) => {
        this.eventVM = null;
        this.ls.hide();
        this.reh.handleFormErrors(this.form, errorResponse);
      });
    } else {
      this.ls.hide();
      this.form.markAllAsTouched();
      this.subject.next(new ModalStateEvent(ModalState.INVALID));

    }
  }

  searchPlaces(event: any) {
    const sub = this.mapService.subscribeToQuerySubject().subscribe((results: any[]) => {
      this.searchResults = results.map(result => ({label: result.display_name, value: result}));
      if (!this.searchResults.length) {
        this.form.get('address').setErrors({incorrect: true, message: 'This is not valid place'});
      }
      sub.unsubscribe();
    });
    this.mapService.searchPlaces(event.query);
  }

  selectPlace(event: AutoCompleteSelectEvent) {
    const item: any = event.value.value;
    const iMarker = {lat: item.lat as number, lon: item.lon as number};

    if (this.mapComponent.layerMarkers.length) {
      this.mapComponent.removeMarker(this.mapComponent.layerMarkers[this.mapComponent.layerMarkers.length - 1]);
    }
    this.mapComponent.addMarker(iMarker);
    this.mapComponent.updateMapCenter(iMarker);
    const addrInput = this.form.get('address');
    // addrInput.setValue(event.value);
    addrInput.setErrors(null);
  }

  emitQuery(queryEvt) {
    this.eventPS.resultSubject$.next(queryEvt.query);
  }

  addOrganizer(item: any) {
    if (this.organizers.find(e => e.alias === item.alias)) {
      return;
    }

    this.organizers.push(item);
  }

  removeOrganizer(item: any) {
    this.organizers = this.organizers.filter(e => e.alias !== item.alias);
  }

  public onFileSelect(event: FilesUploadEventInterface): void {
    event.files.forEach((file: File) => {
      if (!this.files.includes(file)) {
        this.files.push(file);
      }
    });

  }

  onFileRemove(event: FileRemoveEventInterface): void {
    this.removeMedia(event).subscribe((mediaObjectAM: MediaObjectApiModel) => {
      this.mediaObjectAMs = this.mediaObjectAMs.filter(e => e['@id'] !== mediaObjectAM['@id']);
      this.files = this.files.filter(e => e.name !== event.file.name);
      this.closeMediaLoadProcess();
      this.disabled = false;
    });
  }

  removeMedia(event: FileRemoveEventInterface): Observable<any> {
    this.disabled = true;
    const mediaObjectAM = this.mediaObjectAMs.find(
      (mediaObjectAM: MediaObjectApiModel) => mediaObjectAM.originalName === event.file.name || mediaObjectAM.name === event.file.name
    );
    return this.deleteMediaObject(mediaObjectAM);
  }

  public postMediaObject(file): Observable<MediaObjectApiModel> {
    return this.mediaObjectRS.uploadImage(file);
  }

  public deleteMediaObject(mediaObjectAM: MediaObjectApiModel): Observable<MediaObjectApiModel> {
    return this.mediaObjectRS.deleteMediaObject(mediaObjectAM['@id']).pipe(map(() => mediaObjectAM));
  }

  public closeMediaLoadProcess(): void {
    this.mediaProgress = null;
  }

  createOrganizer(user: UserViewModel): OrganizerInterface {
    const info = user.info as PersonalInformationViewModel;
    return Object.assign({
      name: info.firstName + ' ' + info.lastName,
      alias : user.username,
      uri: '/' + user.slug,
      slug: user.slug,
      user: user.toApiModel(),
      avatar: user.avatar.hyperlinks[0]?.uri || 'assets/img/profile-bg-dark.png',
      type: 'user'
    });
  }

  getAddrOptionValue(item: SelectItem): any {
    return item.value;
  }
}

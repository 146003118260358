import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import { HttpClient, HttpResponse } from "@angular/common/http";
import {AuthApiModel} from "../models/auth.api.model";
import {CredentialsApiModel} from "../models/credentials.api.model";
import {MessageApiModel} from "../models/message.api.model";
import {UserApiModel} from "../models/user.api-model";
import {environment} from "../../../environments/environment";
import {plainToClass} from "class-transformer";
import {FacebookLoginResponse, GoogleLoginResponse} from "@capgo/capacitor-social-login";

@Injectable()
export class AuthenticationRestService {

  constructor(
    private http: HttpClient,
  ) {}

  public login(credentials: CredentialsApiModel): Observable<AuthApiModel> {
    return this.http.post<AuthApiModel>(environment.apiUrl + '/users/auth', credentials)
      .pipe(map((successResponse) => plainToClass(AuthApiModel, successResponse)));
  }

  public fbLogin(body: {limitedLogin: boolean, payload: FacebookLoginResponse}): Observable<AuthApiModel> {
    return this.http.post<AuthApiModel>(environment.apiUrl + '/connect/facebook/check', body)
      .pipe(map((successResponse) => plainToClass(AuthApiModel, successResponse)));
  }

  public googleLogin(body: {limitedLogin: boolean, payload: GoogleLoginResponse}): Observable<AuthApiModel> {
    return this.http.post<AuthApiModel>(environment.apiUrl + '/connect/google/check', body)
      .pipe(map((successResponse) => plainToClass(AuthApiModel, successResponse)));
  }

  public getCurrentUser(): Observable<UserApiModel> {
    return this.http.get(environment.apiUrl + '/users/current')
      .pipe(map((successResponse) => plainToClass(UserApiModel, successResponse)))
  }

  public resetPassword(usernameOrEmail: string): Observable<MessageApiModel> {
    const body = JSON.stringify({usernameOrEmail: usernameOrEmail});
    return this.http.post<MessageApiModel>(environment.apiUrl + '/users/password-reset', body);
  }

  public resetConfirm(token: string): Observable<MessageApiModel> {
    const body = JSON.stringify({token: token});
    return this.http.post<MessageApiModel>(environment.apiUrl + '/users/password-reset-confirm', body);
  }

  public postRefreshToken(refreshToken: string): Observable<AuthApiModel> {
    const body = JSON.stringify({refreshToken: refreshToken});
    return this.http.post<AuthApiModel>(environment.apiUrl + '/api/token/refresh', body)
      .pipe(map((successResponse) => plainToClass(AuthApiModel, successResponse)));
  }
}

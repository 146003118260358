import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {SiteLayoutComponent} from './core/layout/site/site-layout.component';
import {AuthGuard} from './core/guards/auth.guard';
import {UserComponent} from './features/user/user.component';
import {PageNotFoundComponent} from './core/pages/page-not-found/page-not-found.component';
import {AccessDeniedComponent} from './core/pages/access-denied/access-denied.component';
import {ErrorComponent} from './core/pages/error/error.component';
import {HomeComponent} from './features/home/home.component';
import {CurrentUserResolverService} from './core/services/current-user.resolver.service';
import {Oauth2Guard} from './core/guards/oauth2.guard';
import {PrivacyComponent} from './features/privacy/privacy.component';
import {BackgroundResolverService} from './core/services/background.resolver.service';
import {NoAuthLayoutComponent} from './shared/no-auth-layout/no-auth-layout.component';
import {PrivacyContentComponent} from './features/privacy/privacy-content/privacy-content.component';
import {LandingComponent} from './core/layout/landing/landing.component';
import {RankingComponent} from './features/ranking/ranking.component';
import {NoAuthGuard} from './core/guards/no-auth.guard';
import {provideClientHydration} from "@angular/platform-browser";
import {provideHttpClient, withFetch, withInterceptorsFromDi} from "@angular/common/http";
import {SearchResultsListComponent} from './core/components/search-results-list/search-results-list.component';

const routes: Routes = [
  {
    path: 'privacy-no-auth', component: NoAuthLayoutComponent, children: [
      {path: '', component: PrivacyContentComponent},
    ],
    resolve: {
      background: BackgroundResolverService,
    }
  },
  {
    path: 'landing', component: LandingComponent, canActivate: [NoAuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '', component: SiteLayoutComponent, canActivate: [AuthGuard, Oauth2Guard], children: [
      {
        path: '', component: HomeComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'common.home',
          disableNgxBackForwardCache: false
        }
      },
      {
        path: 'search-results', component: SearchResultsListComponent,
        data: {
          breadcrumb: 'common.search_results'
        }
      },
      {
        path: 'feed',
        loadChildren: () => import('./features/feed/feed.module').then(m => m.FeedModule),
      },
      {
        path: 'offers',
        loadChildren: () => import('./features/marketplace/marketplace.module').then(m => m.MarketplaceModule),
        data: {
          breadcrumb: 'common.offers'
        }
      },
      {
        path: 'events',
        loadChildren: () => import('./features/event/event.module').then(m => m.EventModule),
      },
      {
        path: 'privacy', component: PrivacyComponent,
        data: {
          breadcrumb: 'common.privacy'
        }
      },
      {
        path: 'ranking',
        component: RankingComponent,
        data: {
          breadcrumb: 'common.ranking'
        }
      },
      {path: '404', component: PageNotFoundComponent},
      {path: '403', component: AccessDeniedComponent},
      {path: '500', component: ErrorComponent},
      {
        path: ':id', component: UserComponent, canActivate: [AuthGuard],
        loadChildren: () => import('./features/user/user.module').then(m => m.UserModule),
      },
    ],
    resolve: {
      currentUser: CurrentUserResolverService
    }
  },
  {path: '', redirectTo: '/', pathMatch: 'full'},
  {path: '**', redirectTo: '404'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    enableTracing: false,
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'disabled',
    initialNavigation: 'enabledBlocking'
})
  ],
  exports: [RouterModule],
  providers: [
    provideClientHydration(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch()
    ),
  ]
})
export class AppRoutingModule {
}

<app-timeline
  [(viewType)]="type"
  [startIndex]="startIndex"
  [writingPostEnabled]="false"
  [postTypes]="postTypes"
  [permissions]="ownerPermissions"
  [projectVM]="projectVM"
  [eventAM]="eventAM"
  [noResultsMsg]="'common.no_media'|translate"
>
</app-timeline>


<app-bottom-sidebar
  [isSidebarOpened]="isSidebarOpened"
  [isBackButtonVisible]="!!isSidebarOpened"
  (onGoBack)=" closeContextMenu()"
  (onHide)="closeContextMenu()"
>
  <ng-container [ngSwitch]="sidebarAction">
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTemp"></ng-container>
  </ng-container>
</app-bottom-sidebar>

<ng-template #defaultTemp>
  <div class="col-12 pt-3 camera-container">
    <p-slideMenu
      *ngIf="items"
      [model]="items"
      [menuWidth]="900"
      styleClass="project-slidemenu"
      [backLabel]="'common.button.back' | translate"
      [viewportHeight]="37.67 * items.length"
    >
    </p-slideMenu>
  </div>
</ng-template>

<div class="layout-topbar shadow-4">
    <div id="layout-topbar" class="layout-topbar-left">
        <a class="layout-topbar-logo" routerLink="/">
<!--            <strong class="orange-text font-bold" style="font-size:1.7em">WatchThisGarage-->
<!--                v0.1 &nbsp;&nbsp;</strong>-->
            <img id="app-logo" src="assets/layout/images/wtg-logo-transparent7-white.webp" alt="wtg watch this garage logo" height="56" width="100">
        </a>

        <a class="layout-menu-button shadow-6" (click)="appMain.onMenuButtonClick($event)" pRipple>
            <i class="pi pi-chevron-right"></i>
        </a>

        <a class="layout-topbar-mobile-button" (click)="appMain.onTopbarMobileButtonClick($event)" pRipple>
            <i class="pi pi-ellipsis-v fs-large"></i>
        </a>
    </div>

    <div class="layout-topbar-right" [ngClass]="{'layout-topbar-mobile-active': appMain.mobileTopbarActive}">
        <div class="layout-topbar-actions-left">
            <p-megaMenu [model]="model" styleClass="layout-megamenu"></p-megaMenu>
        </div>
        <div class="layout-topbar-actions-right">
            <ul class="layout-topbar-items">
                <li class="layout-topbar-item layout-search-item">
                    <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'search')" pRipple>
                        <i class="pi pi-search fs-large"></i>
                    </a>

                    <div class="layout-search-panel p-inputgroup" style="flex-wrap: wrap" [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.search" (@topbarActionPanelAnimation.done)="onSearchAnimationEnd($event)">
                        <span class="p-inputgroup-addon"><i class="pi pi-search" (click)="goToSearchResults()"></i></span>
                        <input
                            #searchInput
                            type="text"
                            pInputText
                            [placeholder]="'shared.top_bar.placeholder.search' | translate"
                            (click)="appMain.searchClick = true"
                            (keydown)="appMain.onSearchKeydown($event)"
                            [(ngModel)]="query"
                            (ngModelChange)="appMain.emitQuery($event)"
                            (keyup.enter)="goToSearchResults()"
                        >
<!--                        <span class="p-inputgroup-addon">-->
<!--                            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" (click)="appMain.searchClick = false; appMain.searchMenuClick = false; appMain.onTopbarMobileButtonClick($event)"></button>-->
<!--                        </span>-->
                        <span class="p-inputgroup-addon">
                            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" (click)="appMain.searchClick = false; appMain.searchMenuClick = false; appMain.onTopbarMobileButtonClick($event)"></button>
                        </span>
                        <app-search-list [phrase]="query" [user]="user" (click)="appMain.searchMenuClick = true;"></app-search-list>
                    </div>
                </li>
                <li class="layout-topbar-item notifications">
                    <a class="layout-topbar-action rounded-circle" (click)="handleNotificationItemClick($event)" pRipple>
                        <span class="p-overlay-badge">
                            <i *ngIf="!newNotificationsCount" class="pi pi-bell fs-large" size="large"></i>
                            <i *ngIf="newNotificationsCount" class="pi pi-bell fs-large" pBadge value="{{newNotificationsCount}}" size="large"></i>
                        </span>
                    </a>

                    <ul class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.activeTopbarItem === 'notifications'">
                        <li *ngIf="newNotifications.length" class="mb-3 flex justify-content-center align-items-center">
                            <span class="px-3 fs-small" [innerHTML]="'shared.top_bar.notifications.new_notifications' | translate: {newNotificationsCount}"></span>
                        </li>
                        <li *ngIf="!newNotifications.length" class="flex justify-content-center align-items-center" [ngClass]="{'mb-3': notifications.length}">
                          <span class="px-3 fs-small">{{'shared.top_bar.notifications.no_new_notifications' | translate}}</span>
                        </li>
                        <p-virtualScroller *ngIf="notifications.length" [value]="notifications" [scrollHeight]="'500px'" [itemSize]="107" [lazy]="true" (onLazyLoad)="loadNextNotificationsChunk($event)">
                          <ng-template pTemplate="item" let-notification>
                            <li class="layout-topbar-action-item">
                              <div class="flex flex-row align-items-center">
                                <app-thumbnail
                                  classList="thumbnail-inline-block"
                                  size="xs"
                                  [circle]="true"
                                  [imgSrc]="notification.fromUser.avatar | mediaObject"
                                ></app-thumbnail>
                                <div class="flex flex-column" [ngClass]="{'ml-3': !app.isRTL, 'mr-3': app.isRTL}" style="flex-grow: 1;">
                                  <div class="flex align-items-center justify-content-between mb-1">
                                    <a><span class="fs-small font-bold">{{notification.fromUser.username}}</span></a>
                                    <small>{{notification.createdAt | dateAgo}}</small>
                                  </div>
                                  <span class="fs-small">
                                    <ng-container [ngSwitch]="notification.subject['@type']">
                                      <ng-container *ngSwitchCase="'Project'">
                                        {{'shared.top_bar.notifications.follow_project' | translate}}
                                        <ng-container [ngSwitch]="true">
                                          <ng-container *ngSwitchCase="!!notification.subject.alias">{{notification.subject.alias}} ({{notification.subject.vehicle.fullVehicleIdentity }})</ng-container>
                                          <ng-container *ngSwitchDefault>{{notification.subject.vehicle.fullVehicleIdentity }}</ng-container>
                                        </ng-container>
                                        {{'shared.top_bar.notifications.from_your_garage' | translate}}
                                      </ng-container>
                                      <ng-container *ngSwitchCase="'User'">
                                        {{'shared.top_bar.notifications.follow_profile' | translate}}
                                      </ng-container>
                                    </ng-container>

                                    </span>
                                </div>
                              </div>
                            </li>
                          </ng-template>
                          <ng-template let-notification pTemplate="loadingItem">
                            Loading...
                          </ng-template>
                        </p-virtualScroller>
                    </ul>
                </li>
<!--                <li class="layout-topbar-item app">-->
<!--                    <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'apps')" pRipple>-->
<!--                        <i class="pi pi-table fs-large"></i>-->
<!--                    </a>-->

<!--                    <div class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.activeTopbarItem === 'apps'">-->
<!--                        <div class="grid grid-nogutter">-->
<!--                            <div class="layout-topbar-action-item col-4">-->
<!--                                <a class="flex align-items-center flex-column text-color" pRipple>-->
<!--                                    <i class="pi pi-image action indigo-bgcolor white-color"></i>-->
<!--                                    <span>Products</span>-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <div class="layout-topbar-action-item col-4">-->
<!--                                <a class="flex align-items-center flex-column text-color" pRipple>-->
<!--                                    <i class="pi pi-file-pdf action orange-bgcolor white-color"></i>-->
<!--                                    <span>Reports</span>-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <div class="layout-topbar-action-item col-4">-->
<!--                                <a class="flex align-items-center flex-column text-color" pRipple>-->
<!--                                    <i class="pi pi-dollar action teal-bgcolor white-color"></i>-->
<!--                                    <span>Balance</span>-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <div class="layout-topbar-action-item col-4">-->
<!--                                <a class="flex align-items-center flex-column text-color" pRipple>-->
<!--                                    <i class="pi pi-cog action pink-bgcolor white-color"></i>-->
<!--                                    <span>Settings</span>-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <div class="layout-topbar-action-item col-4">-->
<!--                                <a class="flex align-items-center flex-column text-color" pRipple>-->
<!--                                    <i class="pi pi-key action bluegrey-bgcolor white-color"></i>-->
<!--                                    <span>Credentials</span>-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <div class="layout-topbar-action-item col-4">-->
<!--                                <a class="flex align-items-center justify-content-center flex-column text-color" pRipple>-->
<!--                                    <i class="pi pi-sitemap action cyan-bgcolor white-color"></i>-->
<!--                                    <span>Sitemap</span>-->
<!--                                </a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </li>-->
                <li class="layout-topbar-item">
                    <a class="layout-topbar-action flex p-dir-row justify-content-center align-items-center px-2 rounded-circle" (click)="appMain.onTopbarItemClick($event, 'profile')" pRipple>
                        <app-thumbnail
                                [imgSrc]="this.user.avatar | mediaObject" [circle]="true" [bordered]="true"
                                stylesList="width: 32px; height: 32px;"
                        ></app-thumbnail>
                    </a>

                    <ul class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.activeTopbarItem === 'profile'" (click)="appMain.resetActiveMenus()">
                      <li class="layout-topbar-action-item" *ngxPermissionsOnly="['ROLE_ADMIN']">
                        <a [routerLink]="['/admin']" class="flex flex-row align-items-center" pRipple>
                          <i class="fas fa-lock" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                          <span>{{'shared.inline_menu.admin_dashboard' | translate}}</span>
                        </a>
                      </li>
                      <li class="layout-topbar-action-item">
                          <a [routerLink]="['/', user.slug, 'garage']" class="flex flex-row align-items-center" pRipple>
                            <i class="fas fa-warehouse" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                            <span>{{'shared.inline_menu.garage' | translate}}</span>
                          </a>
                        </li>
                        <li class="layout-topbar-action-item">
                            <a [routerLink]="['/', user.slug, 'profile']" class="flex flex-row align-items-center" pRipple>
                                <i class="pi pi-user" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span>{{'shared.inline_menu.profile' | translate}}</span>
                            </a>
                        </li>
                      <li class="layout-topbar-action-item">
                        <a [routerLink]="['/', user.slug, 'offers']" class="flex flex-row align-items-center" pRipple>
                          <i class="pi pi-gauge" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                          <span>{{'shared.inline_menu.my_advertisements' | translate}}</span>
                        </a>
                      </li>
                        <li class="layout-topbar-action-item">
                          <a [routerLink]="['/', user.slug, 'settings']" class="flex flex-row align-items-center" pRipple>
                            <i class="pi pi-cog" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                            <span>{{'shared.inline_menu.settings' | translate}}</span>
                          </a>
                        </li>
                        <li class="layout-topbar-action-item">
                          <a class="flex flex-row align-items-center" pRipple>
                            <i class="pi pi-compass" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                            <span>{{'shared.inline_menu.support' | translate}}</span>
                          </a>
                        </li>
                        <li class="layout-topbar-action-item">
                            <a class="flex flex-row align-items-center" pRipple>
                                <i class="pi pi-file-o" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span>{{'shared.inline_menu.terms' | translate}}</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item">
                            <a [routerLink]="['/privacy']" class="flex flex-row align-items-center" pRipple>
                                <i class="pi pi-lock" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span>{{'features.privacy.privacy' | translate}}</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item">
                            <a [routerLink]="['/login']" class="flex flex-row align-items-center" pRipple (click)="logout($event)">
                                <i class="pi pi-power-off" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span>{{'shared.inline_menu.logout' | translate}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
              <li class="layout-topbar-item">
                    <a class="layout-topbar-action rounded-circle" (click)="appMain.onRightMenuButtonClick($event)" pRipple>
                        <i class="pi fs-large" [ngClass]="{'pi-arrow-left': !app.isRTL, 'pi-arrow-right': app.isRTL}"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

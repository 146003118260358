import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppBreadcrumbService} from './app.breadcrumb.service';
import {Subscription} from 'rxjs';
import {MenuItem} from 'primeng/api';
import {TokenDataProviderService} from '../../core/services/token-data-provider.service';
import {CurrentUserViewModel} from '../../core/models/current-user.view-model';
import {Location} from '@angular/common';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ActionsService} from '../footer/actions.service';
import {ViewTypeEnum} from '../../core/enums/view-type.enum';

export let browserRefresh = false;


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
  styleUrls: ['./app.breadcrumb.component.scss']
})
export class AppBreadcrumbComponent implements OnInit, OnDestroy {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  public readonly ViewTypeEnum = ViewTypeEnum;

  subscriptions: Subscription[] = [];

  items: MenuItem[];

  home: MenuItem;

  public currentUser: CurrentUserViewModel;
  public isFocused: boolean = false;

  constructor(
    public breadcrumbService: AppBreadcrumbService,
    public actionsService: ActionsService,
    public tdp: TokenDataProviderService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
  ) {
    this.subscriptions.push(breadcrumbService.itemsHandler.subscribe(response => {
      this.items = response;
    }));

    this.subscriptions.push(this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event) => {
          this.items = this.createBreadcrumbs(this.activatedRoute.root);
        }
      ));

    this.translate.onLangChange.subscribe(() => {
      this.items = this.createBreadcrumbs(this.activatedRoute.root);
    });

    this.home = {
      icon: '',
      routerLink: '/'
    };
  }

  ngOnInit(): void {
    this.tdp.currentUser.subscribe((currentUser: CurrentUserViewModel) => this.currentUser = currentUser);
  }

  ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
  }

  public logout(): void {
    this.tdp.logout();
  }

  public goBack(): void {
    this.location.back();
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      let label = child.snapshot.data[AppBreadcrumbComponent.ROUTE_DATA_BREADCRUMB];

      if (label !== null && label !== undefined) {

        label = this.translate.instant(label);
        breadcrumbs.push({label, url});
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  public appendQuery(): void {
    this.router.navigate([], {queryParams: {q: this.breadcrumbService.query}});
  }

  public resetQuery(): void {
    this.breadcrumbService.query = '';
  }

  onFocus(): void {
    this.isFocused = true;
  }

  onBlur(): void {
    this.isFocused = false;
  }
}

import {AbstractViewModel} from '../../../core/interfaces/abstract-view-model';
import {ProjectApiModel} from '../../../api/models/project.api-model';
import {VehicleViewModel} from './vehicle.view-model';
import {UserViewModel} from '../settings/user.view-model';
import {CurrentUserViewModel} from '../../../core/models/current-user.view-model';
import {MediaObjectViewModel} from '../../../core/models/media-object.view-model';
import {HashtagApiModel} from '../../../api/models/hashtag.api.model';
import {RatingApiModel} from '../../../api/models/rating.api-model';
import {FollowApiModel} from '../../../api/models/follow.api-model';
import {GarageStatusEnum} from './garage-status.enum';
import {ReactionApiModel} from '../../../api/models/reaction.api.model';
import {VehicleAttributes} from "../../../core/interfaces/vehicle-attributes";
import {EquipmentApiModel} from "../../../api/models/equipment.api-model";

export class ProjectViewModel extends AbstractViewModel<ProjectApiModel> {

  public constructor(protected apiModel: ProjectApiModel = new ProjectApiModel()) {
    super(apiModel);
    this.fromApiModel(apiModel);
  }
  public user: UserViewModel|CurrentUserViewModel;
  public vehicle: VehicleViewModel;
  public status: GarageStatusEnum;
  public shortDesc: string = null;
  public alias: string = null;
  public registrationPlate: string = null;
  public vin: string = null;
  public firstRegistrationInCountry: Date = null;
  public externalHistoryAllowedAt: Date = null;
  public slug: string = null;
  public hashtags: HashtagApiModel[] = [];
  public ratings: RatingApiModel[] = [];
  public averageRating: number;
  public ratingCount: number;
  public currentUserRating: number;
  public theme: MediaObjectViewModel = null;
  public thumbnail: string = null;
  public locked: boolean = null;
  public media: string[] = null;
  public follows: FollowApiModel[] = [];
  public reactions: ReactionApiModel[] = [];
  public likes: string[] = [];
  public updatedAt: Date;
  public createdAt: Date;
  public isPlaceholder: boolean = false;
  public attributes: VehicleAttributes;
  public equipment: EquipmentApiModel[] = [];

  public static create(currentUserVM: CurrentUserViewModel, vehicleVM: VehicleViewModel): ProjectViewModel {
    const projectVM = new ProjectViewModel();
    projectVM.user = currentUserVM;
    projectVM.vehicle = vehicleVM;
    return projectVM;
  }

  fromApiModel(apiModel: ProjectApiModel): this {
    this.id = apiModel.id;
    this.shortDesc = apiModel.shortDesc;
    this.alias = apiModel.alias;
    this.slug = apiModel.slug;
    this.vin = apiModel.vin;
    this.registrationPlate = apiModel.registrationPlate;
    this.firstRegistrationInCountry = apiModel.firstRegistrationInCountry as Date;
    this.externalHistoryAllowedAt = apiModel.externalHistoryAllowedAt;
    this.status = apiModel.status;
    this.ratings = apiModel.ratings;
    this.averageRating = apiModel.averageRating;
    this.ratingCount = apiModel.ratingCount;
    this.currentUserRating = apiModel.currentUserRating;
    this.locked = apiModel.locked;
    this.updatedAt = apiModel.updatedAt;
    this.createdAt = apiModel.createdAt;
    this.follows = apiModel.follows || [];
    this.reactions = apiModel.reactions || [];
    this.media = apiModel.media || [];
    this.equipment = apiModel.equipment as EquipmentApiModel[] || [];
    return this;
  }

  getApiModel(): ProjectApiModel {
    return this.apiModel;
  }

  toApiModel(): ProjectApiModel {
    const hashtagStrings  = this.hashtags.map((e: HashtagApiModel) => {
      return e['@id'] ? e['@id'] : e.name;
    });
    this.apiModel.user = this.user['@id'];
    this.apiModel.vehicle = this.vehicle['@id'];
    this.apiModel.shortDesc = this.shortDesc;
    this.apiModel.alias = this.alias;
    this.apiModel.vin = this.vin;
    this.apiModel.registrationPlate = this.registrationPlate;
    this.apiModel.firstRegistrationInCountry = this.firstRegistrationInCountry?.toLocaleDateString();
    this.apiModel.hashtags = hashtagStrings;
    return this.apiModel;
  }
}

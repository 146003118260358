import {AbstractViewModel} from "../interfaces/abstract-view-model";
import {StatusApiModel} from "../../api/models/status.api.model";

export class StatusViewModel extends AbstractViewModel<StatusApiModel> {
  public id: number;
  public name: string;
  public const: string;

  public constructor(protected apiModel: StatusApiModel = new StatusApiModel()) {
    super(apiModel);
    this.fromApiModel(apiModel);
  }

  fromApiModel(apiModel: StatusApiModel): StatusViewModel {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.const = this.apiModel.const;

    return this;
  }

  toApiModel(): StatusApiModel {
    return this.apiModel;
  }

}

<!--<div #container class="w-full h-full relative" (scroll)="onScroll()"-->
<!--&gt;-->
  <div
    NgxFrameInfiniteGrid
    class="infinite-grid w-full h-full relative grid grid-nogutter"
    #ig
    [horizontal]="false"
    [isEqualSize]="true"
    [items]="items"
    [gap]="0"
    [autoResize]="true"
    [frame]="containerWidth < 1024 ? [[1,2,3], [4,5,6]] : [[1,2,3,4], [5,6,7,8]]"
    [useResizeObserver]="true"
    [observeChildren]="true"
    (renderComplete)="setContainerWidth()"
    (requestAppend)="onRequestAppend($event)"
  >
    <div *ngFor="let item of ig.visibleItems; let i = index" class="col-4 md:col-3 item item-padding" [id]="'item' + item.data.id">
      <!--      <span>{{item.data.id}}</span>-->
      <p-checkbox
        styleClass="absolute top-0 left-0"
        [ngModel]="item.data.selected"
        (ngModelChange)="toggleSelectItem(item.data)"
        [binary]="true"
        />
      <img alt="media thumbnail" *ngIf="item.data.type === 'IMAGE'" class="media-thumbnail w-full h-full" [src]="getMediaObject(item.data) | mediaObject" (click)="toggleSelectItem(item.data)"/>
      <video
        (click)="toggleSelectItem(item.data)"
        *ngIf="item.data.type === 'VIDEO'"
        preload="metadata"
        id="video-{{item.data.id}}"
        class="media-thumbnail w-full h-full"
        crossorigin
        muted="muted"
        [src]="getMediaObject(item.data) | mediaObject:{formatType: 'original'}"
        [poster]="getMediaObject(item.data) | mediaObject:{formatType: 'm3u8_timeline'}"
      >
        <!--    <source [src]="mediaObject | mediaObject:{formatType: 'original'}" type="{{mediaObject.mimeType}}">-->
      </video>
    </div>
  </div>
<!--</div>-->


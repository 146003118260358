import {
  afterNextRender,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import type * as L from 'leaflet';
import {MapService} from './map.service';
import {MarkerInterface} from './marker.interface';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {
  @Input() lat: number = 36.890257;
  @Input() lon: number = 30.707417;
  @Input() zoom: number = 12;
  @Input() height: string = '320px';
  @Output() placeSelected = new EventEmitter<any>();
  @ViewChild('map', {static: true}) mapContainer: ElementRef;
  @Input() markers: MarkerInterface[];

  private map: L.Map;
  public layerMarkers: L.Marker[] = [];
  public isRendered: boolean = false;
  private mapModule: any

  constructor(private mapService: MapService) {
    afterNextRender(async () => {
      this.mapModule = await import('leaflet');
      this.initMap();
    })
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  public initMap(): void {
    if (!document.getElementById('map')) {
      console.log('Map container not found', document.getElementById('map'));
      return;
    }
    const leaflet = this.mapModule.default;
    this.map = leaflet.map(this.mapContainer.nativeElement, {
      center: [this.lat, this.lon],
      zoom: 13,
      zoomControl: false,
      attributionControl: false,
      doubleClickZoom: false,
      scrollWheelZoom: 'center',
      dragging: false
    });

    // Dodajemy niestandardowe kafelki mapowe
    leaflet.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png', {
      maxZoom: 19,
    }).addTo(this.map);
    this.isRendered = true;
  }

  updateMapCenter(imarker: MarkerInterface) {
    this.map.setView([imarker.lat, imarker.lon], this.map.getZoom());
  }

  public addMarker(imarker: MarkerInterface): void {
    const leaflet = this.mapModule.default;
    const marker = leaflet.marker([imarker.lat, imarker.lon], {
      draggable: false,
      icon: this.createIcon(imarker),
      title: imarker.title || '',
    });
    marker.addTo(this.map);
    this.layerMarkers.push(marker);
  }

  public removeMarker(marker: L.Marker): void {
    marker.remove();
  }

  private createIcon(imarker: MarkerInterface): L.Icon {
    const leaflet = this.mapModule.default;
    return leaflet.icon({
      iconUrl: imarker.icon ? imarker.icon : 'assets/icons/car/car-meeting.png', // Zmień na właściwą ścieżkę do ikony
      iconSize: [32, 32], // Rozmiar ikony
      iconAnchor: [16, 32], // Punkt kotwiczenia (środek dolnej krawędzi ikony)
      popupAnchor: [0, -32] // Punkt kotwiczenia dla okna popup (nad ikoną)
    });
  }

  // this should be considered as old config for further improvement of OSM
  private oldMapStylesToRefactor(): object {
    return {
      center: {
        lat: 0,
        lng: 0,
        zoom: 12,
        styles: [
          {
            elementType: 'geometry',
            stylers: [
              {color: '#1e1e1e'} // Kolor tła mapy
            ]
          },
          {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [{color: '#2c2c2c'}]
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{color: '#1a2738', lightness: 20}]
          },
          {
            featureType: 'landscape.natural',
            elementType: 'geometry',
            stylers: [{color: '#171c17', lightness: 40}]
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{saturation: -100}, {lightness: 50}]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{saturation: -100}, {lightness: 50}]
          },
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
              {visibility: 'off'}
            ]
          }
        ],
        disableDefaultUI: true, // Usunięcie domyślnych elementów interfejsu
        clickableIcons: false, // Wyłączenie klikalnych ikon
        mapTypeControl: false, // Usunięcie stopki oraz logo Google
        draggable: false
      }
    };
  }
}

import {Injectable} from '@angular/core';
import {MediaObjectRestService} from "../../api/services/media-object.rest.service";
import {forkJoin, Observable, of, Subject} from "rxjs";
import {MediaObjectApiModel} from "../../api/models/media-object.api-model";
import {filter, map, tap} from "rxjs/operators";
import {MediaTypeEnum} from "../enums/media-type.enum";

@Injectable({
  providedIn: 'root',
})
export class MediaUploadService {
  constructor(private mediaObjectRS: MediaObjectRestService) {}

  uploadMedia(files: File[]): Observable<{ mediaObjects$: Observable<MediaObjectApiModel[]>; progress$: Observable<number> }> {
    const progress$ = new Subject<number>();
    const uploadRequests: Observable<MediaObjectApiModel>[] = [];
    const totalFiles = files.length;

    const individualProgress: number[] = Array(totalFiles).fill(0);

    files.forEach((file, index) => {
      const uploadRequest = this.mediaObjectRS.uploadImage(file).pipe(
        tap((progressOrModel) => {
          if (typeof progressOrModel === 'number') {
            individualProgress[index] = progressOrModel;
            const totalProgress = individualProgress.reduce((sum, curr) => sum + curr, 0) / totalFiles;
            progress$.next(totalProgress);
          }
        }),
        filter((progressOrModel) => typeof progressOrModel !== 'number') // Filtruj, aby pozostały tylko modele
      ) as Observable<MediaObjectApiModel>;
      uploadRequests.push(uploadRequest);
    });

    if (!uploadRequests.length) {
      progress$.next(100); // Jeśli brak plików, zakończ progress
      progress$.complete();
      return of({ mediaObjects$: of([]), progress$: of(100) });
    }

    const mediaObjects$ = forkJoin(uploadRequests).pipe(
      tap(() => {
        progress$.next(100); // Postęp końcowy
        progress$.complete();
      })
    );

    return of({ mediaObjects$: mediaObjects$, progress$: progress$.asObservable() });
  }
}



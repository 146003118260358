import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {App as CapacitorApp} from '@capacitor/app';
import {ModalService} from './shared/modal/modal.service';
import {isPlatformBrowser} from "@angular/common";
import {AppTrackingTransparency} from "capacitor-plugin-app-tracking-transparency";
import {Capacitor} from "@capacitor/core";

@Component({
  selector: 'app-root',
  template: `
    <app-notification></app-notification>
    <app-loader></app-loader>
    <router-outlet></router-outlet>
    <app-confirm-dialog></app-confirm-dialog>
    <app-modal></app-modal>`,
})
export class AppComponent implements OnInit {

  topbarTheme: string = 'dark';

  menuTheme: string = 'dark';

  layoutMode: string = 'dark';

  menuMode: string = 'overlay';

  inlineMenuPosition: string = 'top';

  inputStyle: string = 'outlined';

  ripple: boolean = true;

  isRTL: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly primengConfig: PrimeNGConfig,
    private readonly ms: ModalService,
  ) {
  }

  async ngOnInit() {
    this.primengConfig.ripple = true;
    if (Capacitor.getPlatform() === 'ios') {
      await AppTrackingTransparency.requestPermission();
    }

    CapacitorApp.addListener('backButton', ({canGoBack}) => {
      console.log('back button pressed')
      if (!canGoBack) {
        CapacitorApp.exitApp();
      } else {
        if (this.ms.isVisible) {
          this.ms.close();
          return;
        }
        if (isPlatformBrowser(this.platformId)) {
          window.history.back();
        }
      }
    });
  }
}

export const environment = {
  production: true,
  apiUrl: 'https://watchthisgarage.com/api',
  mercureUrl: 'https://watchthisgarage.com/.well-known/mercure',
  facebookAppId: '1181966833932707',
  facebookClientToken: '7e73001ba73ef50f818ec1b7e26ffaa9',
  googleIosClientId: '243430395756-4vkj4657kcbanp3nql6h0g8bnit5upef.apps.googleusercontent.com',
  googleAndroidWebClientId: '243430395756-0nj9t7nk6vb50tast0btn2q9da342m59.apps.googleusercontent.com',
  version: 1
};

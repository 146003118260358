import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalComponent} from './modal.component';
import {CurrentPasswordComponent} from './components/current-password/current-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalDirective} from './directives/modal.directive';
import {AddVehicleComponent} from './components/add-vehicle/add-vehicle.component';
import {RemoveComponent} from './components/remove/remove.component';
import {ChangeStatusComponent} from './components/change-status/change-status.component';
import {ReactionListComponent} from './components/reaction-list/reaction-list.component';
import {SpinnerModule} from '../spinner/spinner.module';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {TranslateModule} from '@ngx-translate/core';
import {AddGoalComponent} from './components/add-goal/add-goal.component';
import {PickListModule} from 'primeng/picklist';
import {CalendarModule} from 'primeng/calendar';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ColorPickerModule} from 'primeng/colorpicker';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SelectButtonModule} from 'primeng/selectbutton';
import {FileUploadModule} from 'primeng/fileupload';
import {RippleModule} from 'primeng/ripple';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {ThumbnailModule} from '../thumbnail/thumbnail.module';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {PipesModule} from '../../core/pipes/pipes.module';
import {SkeletonModule} from 'primeng/skeleton';
import {ProjectDescriptionComponent} from './components/project-description/project-description.component';
import {HtmlEditorModule} from '../html-editor/html-editor.module';
import {EndCapModule} from '../end-cap/end-cap.module';
import {CardModule} from 'primeng/card';
import {HistoryPostComponent} from './components/history-post/history-post.component';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {InputNumberModule} from 'primeng/inputnumber';
import {AddMediaComponent} from './components/add-media/add-media.component';
import {AddEventComponent} from './components/add-event/add-event.component';
import {EditorModule} from 'primeng/editor';
import {ChipModule} from 'primeng/chip';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {RxReactiveFormsModule} from '@rxweb/reactive-form-validators';
import {ParticipationListComponent} from './components/participation-list/participation-list.component';
import {OverlayGalleryComponent} from './components/overlay-gallery/overlay-gallery.component';
import {SwiperModule} from 'swiper/angular';
import {MediaViewModule} from '../media-view/media-view.module';
import {NgxPermissionsModule} from 'ngx-permissions';
import {FollowListComponent} from './components/follow-list/follow-list.component';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {SpeedDialModule} from '../speed-dial/speed-dial.module';
import {ItemActionDialComponent} from './components/item-action-dial/item-action-dial.component';
import {CustomCameraPageModule} from '../../features/custom-camera/custom-camera.module';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MapModule} from '../map/map.module';
import {HashtagsModule} from '../hashtags/hashtags.module';
import {SearchFiltersComponent} from './components/search-filters/search-filters.component';
import {SliderModule} from "primeng/slider";
import {FieldsetModule} from "primeng/fieldset";
import {
  AddVehicleAdvertisementComponent
} from "./components/add-vehicle-advertisement/add-vehicle-advertisement.component";
import {StepperModule} from "primeng/stepper";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {AvatarModule} from "primeng/avatar";
import {MediaUploaderComponent} from "../media/media-uploader/media-uploader.component";
import {MediaChoiceComponent} from "./components/media-choice/media-choice.component";
import {MediaModule} from "../media/media.module";
import {PanelModule} from "primeng/panel";


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SpinnerModule,
        DropdownModule,
        DialogModule,
        ButtonModule,
        InputTextModule,
        TranslateModule,
        PickListModule,
        CalendarModule,
        InputTextareaModule,
        InputSwitchModule,
        ColorPickerModule,
        RadioButtonModule,
        FormsModule,
        SelectButtonModule,
        FileUploadModule,
        RippleModule,
        VirtualScrollerModule,
        ThumbnailModule,
        TieredMenuModule,
        PipesModule,
        SkeletonModule,
        HtmlEditorModule,
        EndCapModule,
        CardModule,
        TableModule,
        CheckboxModule,
        InputNumberModule,
        EditorModule,
        ChipModule,
        AutoCompleteModule,
        RxReactiveFormsModule,
        SwiperModule,
        MediaViewModule,
        NgxPermissionsModule,
        EmojiModule,
        SpeedDialModule,
        CustomCameraPageModule,
        ConfirmDialogModule,
        MapModule,
        HashtagsModule,
        SliderModule,
        FieldsetModule,
        StepperModule,
        InputGroupModule,
        InputGroupAddonModule,
        AvatarModule,
        MediaUploaderComponent,
        PanelModule
    ],
  declarations: [
    ModalComponent, CurrentPasswordComponent, ModalDirective, AddVehicleComponent, RemoveComponent, OverlayGalleryComponent,
    ChangeStatusComponent, ReactionListComponent, FollowListComponent, AddGoalComponent, ProjectDescriptionComponent, HistoryPostComponent,
    AddMediaComponent, AddEventComponent, ParticipationListComponent, OverlayGalleryComponent, ItemActionDialComponent, ConfirmDialogComponent,
    SearchFiltersComponent, AddVehicleAdvertisementComponent
  ],
  exports: [ModalComponent, ConfirmDialogComponent]
})
export class ModalModule {
}

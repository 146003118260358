import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {AbstractApiModel} from './abstract.api.model';
import {RatingApiModel} from './rating.api-model';
import {FollowApiModel} from './follow.api-model';
import {GarageStatusEnum} from '../../features/user/garage/garage-status.enum';
import {ReactionApiModel} from './reaction.api.model';
import {VehicleAttributes} from "../../core/interfaces/vehicle-attributes";
import {EquipmentApiModel} from "./equipment.api-model";
import {VehicleApiModel} from "./vehicle.api-model";

@Exclude()
export class ProjectApiModel extends AbstractApiModel {
  @Expose() public id: number;
  @Expose() public user: string;
  @Expose() public vehicle: VehicleApiModel|string;
  @Expose() public status: GarageStatusEnum;
  @Expose() public sections: string[];
  @Expose() public shortDesc: string;
  @Expose() public alias: string;
  @Expose() public slug: string;
  @Expose() public vin: string = null;
  @Expose() public registrationPlate: string = null;
  @Type(() => Date)
  @Transform(({ value }: { value: Date }) => {
    return value.toUTCString();
  }, { toPlainOnly: true })
  @Expose() public firstRegistrationInCountry: Date|string = null;
  @Expose() public externalHistoryAllowedAt: Date = null;
  @Expose() public hashtags: string[];
  @Type(() => RatingApiModel)
  @Expose() public ratings: RatingApiModel[];
  @Expose() public averageRating: number;
  @Expose() public ratingCount: number;
  @Expose() public currentUserRating: number;
  @Expose() public targets: string[];
  @Expose() public theme: string;
  @Expose() public thumbnail: string;
  @Expose() public locked: boolean;
  @Expose() public media: string[];
  @Type(() => FollowApiModel)
  @Expose() public follows: FollowApiModel[];
  @Type(() => ReactionApiModel)
  @Expose() public reactions: ReactionApiModel[];
  @Expose() public likes: string[];
  @Expose() public updatedAt: Date;
  @Expose() public createdAt: Date;
  @Expose() public attributes: VehicleAttributes;
  @Type(() => EquipmentApiModel)
  @Expose() public equipment: EquipmentApiModel[]|string[];
}

import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {GoogleLoginResponse, SocialLogin} from "@capgo/capacitor-social-login";
import {LoginOptions} from "@capgo/capacitor-social-login/dist/esm/definitions";
import {Capacitor} from "@capacitor/core";
import {AbstractOauthService} from "./abstract-oauth-service";



@Injectable({
  providedIn: 'root'
})
export class GoogleService extends AbstractOauthService {
  googleLoginType = "online";

  constructor(
  ) {
    super();
    const oauthStateKey = localStorage.getItem('OAUTH_STATE_KEY');
    if (oauthStateKey) {
      console.log("OAUTH_STATE_KEY found");
      const result = this.handleOAuthRedirect();
      console.log(result);
      if (result) {
        if (window.opener) {
          window.opener.postMessage(
            { type: "oauth-response", ...result.result },
            window.location.origin
          );
        }
        window.close();
      }
    }
  }

  async init(): Promise<void> {
    let config: any = {}

    if (Capacitor.getPlatform() === 'ios') {
      config['iOSClientId'] = environment.googleIosClientId;
    } else {
      config['webClientId'] = environment.googleAndroidWebClientId;
    }
    // config['mode'] = 'offline';
    await SocialLogin.initialize({
      google: config,
    });
  }
  login(limited: boolean = false): Promise<{ provider: 'google', result: GoogleLoginResponse}> {
    const GOOGLE_SCOPES =  [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/calendar.events",
      "openid",
    ];

    const loginOptions: LoginOptions = {
      provider: 'google',
      options: {
        scopes: GOOGLE_SCOPES
      },
    };

    return (async () => {
      AbstractOauthService.logout();

      let res = null;
      if (Capacitor.getPlatform() === 'web') {
        res = await this.fallbackToTraditionalOAuth(GOOGLE_SCOPES)
      } else {
        res = await SocialLogin.login(loginOptions);
      }

      return res;
    })();
  }

  async fallbackToTraditionalOAuth(scopes: string[]): Promise<{ provider: 'google', result: GoogleLoginResponse }> {
    const uniqueScopes = [...new Set([...scopes, "openid"])];
    const params = new URLSearchParams({
      client_id: environment.googleAndroidWebClientId,
      redirect_uri: window.location.href,
      response_type: this.googleLoginType === "offline" ? "code" : "token id_token",
      scope: uniqueScopes.join(" "),
      nonce: Math.random().toString(36).substring(2),
      include_granted_scopes: "true",
      state: "popup",
    });
    const url = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;
    const width = 500;
    const height = 600;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    try {
      localStorage.setItem('OAUTH_STATE_KEY', "true");
      const popup = window.open(url, "Google Sign In", `width=${width},height=${height},left=${left},top=${top},popup=1`);
      if (!popup) {
        throw new Error("Failed to open popup");
      }

      return new Promise((resolve, reject) => {
        let timeout: any;

        const monitorPopup = setInterval(() => {
          try {
            if (popup.closed) {
              clearInterval(monitorPopup);
              reject(new Error("Popup closed before completing login"));
              return;
            }

            const popupHash = popup.location.hash;
            if (popupHash) {
              clearInterval(monitorPopup);
              clearTimeout(timeout);

              const params = new URLSearchParams(popupHash.substring(1));
              const accessToken = params.get("access_token");
              const idToken = params.get("id_token");

              if (accessToken && idToken) {
                const profile = this.parseJwt(idToken);
                this.persistStateGoogle(accessToken, idToken);
                resolve({
                  provider: "google",
                  result: {
                    accessToken: {
                      token: accessToken,
                    },
                    idToken,
                    profile: {
                      email: profile.email || null,
                      familyName: profile.family_name || null,
                      givenName: profile.given_name || null,
                      id: profile.sub || null,
                      name: profile.name || null,
                      imageUrl: profile.picture || null,
                    },
                    responseType: "online",
                  },
                });
                popup.close();

              } else {
                popup.close();
                reject(new Error("Invalid OAuth response"));
              }
            }
          } catch (err) {
            // Cross-origin issue: ignore until the hash is available
          }
        }, 100);

        timeout = setTimeout(() => {
          clearInterval(monitorPopup);
          popup.close();
          reject(new Error("OAuth timeout after 5 minutes"));
        }, 300000);

        popup.onbeforeunload = () => {
          clearInterval(monitorPopup);
          clearTimeout(timeout);
          reject(new Error("Popup closed by the user"));
        };
      });
    } catch (err) {
      console.error("Error during fallback OAuth:", err);
      throw err;
    }
  }

  persistStateGoogle(accessToken: string, idToken: string): void {
    try {
      window.localStorage.setItem("capgo_social_login_google_state", JSON.stringify({ accessToken, idToken }));
    }
    catch (e) {
      console.error("Cannot persist state google", e);
    }
  }

  parseJwt(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(atob(base64)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""));
    return JSON.parse(jsonPayload);
  }

  handleOAuthRedirect() {
    const paramsRaw = new URL(window.location.href).searchParams;
    const code = paramsRaw.get("code");
    if (code && paramsRaw.has("scope")) {
      return {
        provider: "google",
        result: {
          provider: "google",
          result: {
            serverAuthCode: code,
          },
        },
      };
    }
    const hash = window.location.hash.substring(1);
    console.log("handleOAuthRedirect", window.location.hash);
    if (!hash)
      return;
    console.log("handleOAuthRedirect ok");
    const params = new URLSearchParams(hash);
    const accessToken = params.get("access_token");
    const idToken = params.get("id_token");
    if (accessToken && idToken) {
      localStorage.removeItem('OAUTH_STATE_KEY');
      const profile = this.parseJwt(idToken);
      return {
        provider: "google",
        result: {
          accessToken: {
            token: accessToken,
          },
          idToken,
          profile: {
            email: profile.email || null,
            familyName: profile.family_name || null,
            givenName: profile.given_name || null,
            id: profile.sub || null,
            name: profile.name || null,
            imageUrl: profile.picture || null,
          },
        },
      };
    }
    return null;
  }

}

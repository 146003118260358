import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {environment} from "../../../environments/environment";
import {FacebookLoginResponse, SocialLogin} from "@capgo/capacitor-social-login";
import {LoginOptions} from "@capgo/capacitor-social-login/dist/esm/definitions";
import {AbstractOauthService} from "./abstract-oauth-service";


@Injectable({
  providedIn: 'root'
})
export class FacebookService  extends AbstractOauthService {

  constructor(
  ) {
    super();
  }

  async initFacebook(): Promise<void> {
    await SocialLogin.initialize({
      facebook: {
        appId: environment.facebookAppId,
        clientToken: environment.facebookClientToken,
      },
    });
  }
  loginWithFacebook(limited: boolean = false): Promise<{ provider: 'facebook', result: FacebookLoginResponse}> {
    const FACEBOOK_PERMISSIONS = ['email', 'public_profile'];
    const loginOptions: LoginOptions = {
      provider: 'facebook',
      options: {
        limitedLogin: limited,
        permissions: FACEBOOK_PERMISSIONS,
      },
    };

    return (async () => {
      AbstractOauthService.logout();
      let res = await SocialLogin.login(loginOptions);
      const result = res.result = res.result as FacebookLoginResponse;
      const refreshDate = new Date(result.accessToken['refreshDate']);
      const currentTime = new Date();
      if (currentTime > refreshDate) {
        await SocialLogin.logout(loginOptions);
        res = await SocialLogin.login(loginOptions);
      }

      return res;
    })();
  }
}

import {AbstractViewModel} from '../interfaces/abstract-view-model';
import {MediaObjectApiModel} from '../../api/models/media-object.api-model';
import {HyperlinkViewModel} from './hyperlink.view-model';
import {MediaObjectInterface} from '../interfaces/media-object.interface';

export class MediaObjectViewModel extends AbstractViewModel<MediaObjectApiModel> implements MediaObjectInterface {
  public name: string;
  public originalName: string;
  public size: string;
  public mimeType: string;
  public dimensions: number[];
  public type: string;
  public m3u8: string;
  public hyperlinks: HyperlinkViewModel[];
  public selected = false;

  public constructor(protected apiModel: MediaObjectApiModel = new MediaObjectApiModel()) {
    super(apiModel);
    this.fromApiModel(apiModel);
  }

  fromApiModel(apiModel: MediaObjectApiModel): MediaObjectViewModel {
    this.name = this.apiModel.name;
    this.originalName = this.apiModel.originalName;
    this.size = this.apiModel.size;
    this.mimeType = this.apiModel.mimeType;
    this.dimensions = this.apiModel.dimensions;
    this.type = this.apiModel.type;
    this.m3u8 = this.apiModel.m3u8;
    this.hyperlinks = this.apiModel.hyperlinks ?  this.apiModel.hyperlinks.map(e => new HyperlinkViewModel(e)) : [];

    return this;
  }

  toApiModel(): MediaObjectApiModel {
    this.apiModel['@id'] = this['@id'];
    this.apiModel.id = this.id;
    this.apiModel.mimeType = this.mimeType;
    this.apiModel.dimensions = this.dimensions;
    this.apiModel.type = this.type;
    this.apiModel.m3u8 = this.m3u8;

    return this.apiModel;
  }
}


<div #container class="flex flex-column h-full">
  <div
    class="flex align-items-center justify-content-center w-full"
  >
    <div
      class="w-full flex flex-column flex-shrink-0 flex-grow-0"
    >
      <div class="w-full shadow-3 advertisement-panel bg-blue">
        <div class="grid grid-nogutter col p-0">
          <div
            pRipple
            class="col-4 p-2 cursor-pointer bg-blue text-center"
            [ngClass]="{
                                'transition-duration-500':
                                    activeTab === 'register'
                            }"
            (click)="clickNext('register')"
          >
            <div class="line-height-3">
              <i
                style="width: 20px"
                class="pi pi-sign-in opacity-40 text-white"
                [ngClass]="{
                                        'opacity-100': activeTab === 'register'
                                    }"
              ></i>
            </div>
            <div
              class="text-white opacity-40 text-lg line-height-3"
              [ngClass]="{
                                    'opacity-100': activeTab === 'register'
                                }"
            >
              {{'common.settings'|translate}}
            </div>
          </div>
          <div
            pRipple
            class="col-4 p-2 cursor-pointer bg-blue text-center"
            [ngClass]="{
            'pointer-events-none': selectedProjectVM === null,
                'transition-duration-500': activeTab === 'tier'
            }"
            (click)="selectedProjectVM!==null ? clickNext('tier'): false"
          >
            <div class="line-height-3">
              <i
                style="width: 20px"
                [ngClass]="{
                                        'opacity-100': activeTab === 'tier'
                                    }"
                class="pi pi-clone opacity-40 text-white"
              ></i>
            </div>
            <div
              class="text-white opacity-40 text-lg line-height-3"
              [ngClass]="{
                                    'opacity-100': activeTab === 'tier'
                                }"
            >
              {{'common.vehicle_data'|translate}}
            </div>
          </div>
          <div
            pRipple
            class="col-4 p-2 cursor-pointer bg-blue text-center"
            [ngClass]="{
              'pointer-events-none': selectedProjectVM === null,
              'transition-duration-500': activeTab === 'payment'
            }"
            (click)="selectedProjectVM !== null  && (form.touched && !form.invalid) ? clickNext('payment') : false"
          >
            <div class="line-height-3">
              <i
                style="width: 20px"
                [ngClass]="{
                                        'opacity-100': activeTab === 'payment'
                                    }"
                class="pi pi-shopping-cart opacity-40 text-white"
              ></i>
            </div>
            <div
              class="text-white opacity-40 text-lg line-height-3 transition-duration-500 transition-ease-in-out"
              [ngClass]="{
                                    'opacity-100': activeTab === 'payment'
                                }"
            >
              {{'common.confirm'|translate}}
            </div>
          </div>
          <div
            class="absolute bottom-0 bg-white"
            style="height: 2px; visibility: hidden"
            [@tabBar]="
                                activeTab === 'register'
                                    ? 'register'
                                    : activeTab === 'tier'
                                    ? 'tier'
                                    : 'payment'
                            "
          ></div>
        </div>
      </div>
      <div
        class="py-5 hidden step"
        [ngClass]="{ 'w-full flex flex-column flex-grow-1': activeTab === 'register' }"
      >
        <div #container class="grid grid-nogutter h-full w-full">
          <div class="col-12 lg:col-6">
            <h1 class="text-sm m-0 mb-5 text-color-secondary">
              {{ 'common.initial_setup'|translate|uppercase }}
            </h1>
            <div class="py-3 flex gap-2 w-full">
              <p-inputSwitch [inputId]="'isRelatedToExistingVehicle'"
                             [(ngModel)]="isRelatedToExistingVehicle"
                             [trueValue]="true" [falseValue]="false"
                             (onChange)="!$event.checked ? selectedProjectVM = null : null"/>
              <label [for]="'isRelatedToExistingVehicle'">{{ 'shared.modal.modal_service.add_vehicle_advertisement.is_related_to_existing_vehicle'|translate }}</label>
            </div>
            <div class="py-3 flex gap-2">
              <p-inputSwitch [inputId]="'isAnonymousAdvertisement'" [(ngModel)]="isAnonymousAdvertisement"
                             [trueValue]="true" [falseValue]="false"/>
              <label [for]="'isAnonymousAdvertisement'">{{ 'shared.modal.modal_service.add_vehicle_advertisement.anonymous_advertisement'|translate }}</label>
            </div>
            <div *ngIf="!isRelatedToExistingVehicle" class="py-3 flex gap-2 w-full">
              <p-inputSwitch [inputId]="'createNewProject'"
                             [(ngModel)]="createNewProject"
                             [trueValue]="true" [falseValue]="false"
                             (onChange)="!$event.checked ? selectedProjectVM = null : null"/>
              <label [for]="'createNewProject'">{{ 'shared.modal.modal_service.add_vehicle_advertisement.create_new_profile'|translate }}</label>
            </div>
          </div>
          <div class="col-12 lg:col-6" *ngIf="isRelatedToExistingVehicle">
            <h1 class="text-sm mb-5 text-color-secondary">
              {{ 'common.choose_vehicle_from_garage'|translate|uppercase }}
            </h1>
            <div class="w-full mb-5">
              <!--              <p-autoComplete-->
              <!--                styleClass="mt-5 w-full"-->
              <!--                [placeholder]="'shared.modal.modal_service.add_event.search'| translate"-->
              <!--                [suggestions]="filteredProjects"-->
              <!--                [forceSelection]="true"-->
              <!--                [dropdown]="true"-->
              <!--                [showClear]="true"-->
              <!--                [(ngModel)]="selectedProjectVM"-->
              <!--                (completeMethod)="searchProjects($event.query)"-->
              <!--                [optionLabel]="getLabel"-->
              <!--              >-->
              <!--                <ng-template let-result pTemplate="item">-->
              <!--                  <div class="text-white">{{ result.vehicle.fullVehicleIdentity }} {{ result.alias ? '| ' + result.alias : '' }}</div>-->
              <!--                </ng-template>-->
              <!--              </p-autoComplete>-->
              <p-dropdown
                styleClass="w-full"
                [classList]="'w-100'"
                [appendTo]="'body'"
                [options]="projectVMs"
                [(ngModel)]="selectedProjectVM"
                [filter]="true"
                [filterFields]="['alias', 'vehicle.fullVehicleIdentity']"
                [showClear]="true"
                [placeholder]="('common.choose_or_search'|translate)"
                (onChange)="processSelectedProject()"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="text-white flex align-items-center gap-2" *ngIf="selectedProjectVM">
                    <p-avatar [image]="selectedProjectVM.theme|mediaObject" [styleClass]="'rounded-circle'"
                              shape="circle"/>
                    {{ ((selectedProjectVM.alias ? selectedProjectVM.alias + ' | ' : '') + selectedProjectVM.vehicle.fullVehicleIdentity)|truncateOnWord:50: '...' }}
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="text-white flex align-items-center gap-2">
                    <p-avatar [image]="item.theme|mediaObject" [styleClass]="'rounded-circle'" shape="circle"/>
                    {{ ((item.alias ? item.alias + ' | ' : '') + item.vehicle.fullVehicleIdentity)|truncateOnWord:50: '...' }}
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-12 lg:col-6" *ngIf="!isRelatedToExistingVehicle">
            <app-add-vehicle (onVehicleSelected)="configureVehicle($event)"></app-add-vehicle>
          </div>
        </div>
        <div class="grid grid-nogutter h-full w-full">
          <div class="col-12 lg:col-6">
            <h1 class="text-sm my-5 text-color-secondary">
              {{ 'shared.modal.modal_service.add_vehicle_advertisement.advertisement_type'|translate|uppercase }}
            </h1>
            <div class="pt-2 pb-3 mb-5 flex gap-2">
              <div class="flex flex-wrap gap-3" [ngClass]="{'flex-column': container.clientWidth < 768}">
                <div class="flex align-items-center">
                  <p-radioButton
                    name="advertisementTypeSale"
                    [value]="AdvertisementTypeEnum.VEHICLE_SALE"
                    [(ngModel)]="advertisementType"
                    inputId="advertisementTypeSale"/>
                  <label [for]="'advertisementTypeSale'" class="ml-2">
                    {{'common.sale'|translate}}
                  </label>
                </div>

                <div class="flex align-items-center">
                  <p-radioButton
                    name="advertisementTypeRent"
                    [value]="AdvertisementTypeEnum.RENT"
                    [(ngModel)]="advertisementType"
                    inputId="advertisementTypeRent"/>
                  <label [for]="'advertisementTypeRent'" class="ml-2">
                    {{'common.rent'|translate}}
                  </label>
                </div>
                <div class="flex align-items-center">
                  <p-radioButton
                    name="advertisementTypeLease"
                    [value]="AdvertisementTypeEnum.LEASE"
                    [(ngModel)]="advertisementType"
                    inputId="advertisementTypeLease"/>
                  <label [for]="'advertisementTypeLease'" class="ml-2">
                    {{'common.lease'|translate}}
                  </label>
                </div>
                <div class="flex align-items-center">
                  <p-radioButton
                    name="advertisementTypeService"
                    [value]="AdvertisementTypeEnum.SERVICE"
                    [(ngModel)]="advertisementType"
                    inputId="advertisementTypeService"/>
                  <label [for]="'advertisementTypeService'" class="ml-2">
                    {{'common.service'|translate}}
                  </label>
                </div>
              </div>
            </div>
            <h1 class="text-sm my-5 text-color-secondary">
              {{ 'shared.modal.modal_service.add_vehicle_advertisement.publication_period'|translate|uppercase }}
            </h1>
            <div class="pt-2 pb-3 mb-5 flex gap-2">
              <div class="flex flex-wrap gap-3" [ngClass]="{'flex-column': container.clientWidth < 768}">
                <div class="flex align-items-center">
                  <p-radioButton
                    name="advertisementPublicationPeriod2W"
                    [value]="AdvertisementPublicationPeriodEnum.TWO_WEEKS"
                    [(ngModel)]="advertisementPublicationPeriod"
                    inputId="advertisementPublicationPeriod2W"/>
                  <label [for]="'advertisementPublicationPeriod2W'" class="ml-2">
                    {{ 'common.two_weeks'|translate }}
                  </label>
                </div>

                <div class="flex align-items-center">
                  <p-radioButton
                    name="advertisementPublicationPeriod1M"
                    [value]="AdvertisementPublicationPeriodEnum.ONE_MONTH"
                    [(ngModel)]="advertisementPublicationPeriod"
                    inputId="advertisementPublicationPeriod1M"/>
                  <label [for]="'advertisementPublicationPeriod1M'" class="ml-2">
                    {{ 'common.one_month'|translate }}
                  </label>
                </div>
                <div class="flex align-items-center">
                  <p-radioButton
                    name="advertisementPublicationPeriod3M"
                    [value]="AdvertisementPublicationPeriodEnum.THREE_MONTHS"
                    [(ngModel)]="advertisementPublicationPeriod"
                    inputId="advertisementPublicationPeriod3M"/>
                  <label [for]="'advertisementPublicationPeriod3M'" class="ml-2">
                    {{ 'common.three_months'|translate }}
                  </label>
                </div>
                <div class="flex align-items-center">
                  <p-radioButton
                    name="advertisementPublicationPeriod6M"
                    [value]="AdvertisementPublicationPeriodEnum.SIX_MONTHS"
                    [(ngModel)]="advertisementPublicationPeriod"
                    inputId="advertisementPublicationPeriod6M"/>
                  <label [for]="'advertisementPublicationPeriod6M'" class="ml-2">
                    {{ 'common.six_months'|translate }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 lg:col-6">
            <h1 class="text-sm my-5 text-color-secondary">
              {{ 'shared.modal.modal_service.add_vehicle_advertisement.optional_parameters'|translate|uppercase }}
            </h1>
            <div class="py-3 flex gap-2">
              <p-inputSwitch [inputId]="'showHistory'" [(ngModel)]="showHistory"
                             [trueValue]="true" [falseValue]="false"/>
              <label [for]="'showHistory'">{{ 'shared.modal.modal_service.add_vehicle_advertisement.show_vehicle_history'|translate }}</label>
            </div>
            <div class="py-3 flex gap-2">
              <p-inputSwitch [inputId]="'showInvestments'" [(ngModel)]="showInvestments"
                             [trueValue]="true" [falseValue]="false"/>
              <label [for]="'showInvestments'">{{ 'shared.modal.modal_service.add_vehicle_advertisement.show_investments_details'|translate }}</label>
            </div>
            <div class="py-3 flex gap-2">
              <p-inputSwitch [inputId]="'showRankingPosition'" [(ngModel)]="showRankingPosition"
                             [trueValue]="true" [falseValue]="false"/>
              <label [for]="'showRankingPosition'">{{ 'shared.modal.modal_service.add_vehicle_advertisement.show_ranking_position'|translate }}</label>
            </div>
            <div class="py-3 flex gap-2">
              <p-inputSwitch [inputId]="'showProfileLink'" [(ngModel)]="showProfileLink"
                             [trueValue]="true" [falseValue]="false"/>
              <label [for]="'showProfileLink'">{{ 'shared.modal.modal_service.add_vehicle_advertisement.show_profile_link'|translate }}</label>
            </div>
            <div class="py-3 flex gap-2">
              <p-inputSwitch [inputId]="'showModifiedParameters'" [(ngModel)]="showModifiedParameters"
                             [trueValue]="true" [falseValue]="false"/>
              <label [for]="'showModifiedParameters'">{{ 'shared.modal.modal_service.add_vehicle_advertisement.show_modified_parameters'|translate }}</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="h-full hidden py-5 step"
        [ngClass]="{ 'flex flex-grow-1': activeTab === 'tier' }"
      >
        <div [formGroup]="form" class="grid grid-nogutter w-full">
          <div *ngIf="AdvertisementTypeEnum.SERVICE === advertisementType" class="col-12">
            <div class="mb-4 p-fluid p-field p-float-label">
              <input type="text" formControlName="title" pInputText/>
              <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.name'|translate }} <span
                class="text-danger">*</span></label>
              <div *ngIf="form.get('name').invalid && form.get('name').touched" class="app-form-error">
                <p>{{ form.get('name')['errorMessage'] }}</p>
                <p *ngIf="form.get('name').errors?.custom">{{ form.get('name').errors.custom }}</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <app-media-uploader [currentUserVM]="currentUserVM" [projectVM]="selectedProjectVM"></app-media-uploader>
          </div>
          <div class="col-12 w-full">
            <div class="mb-4 p-fluid p-field p-float-label">
              <p-editor
                [placeholder]="'shared.modal.modal_service.add_vehicle_advertisement.description'|translate"
                [formControlName]="'description'"
                [style]="{'height':'200px'}"
              ></p-editor>
              <div *ngIf="form.get('description').invalid && form.get('description').touched" class="app-form-error">
                <p>{{ form.get('description')['errorMessage'] }}</p>
                <p *ngIf="form.get('description').errors?.custom">{{ form.get('description').errors.custom }}</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="border-1 surface-border border-round mb-4">
              <span
                class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'common.price'|translate }}</span>
              <div class="grid grid-nogutter py-1 px-2 align-items-center">
                <div class="col-12 md:w-auto md:col-fixed py-2">
                  <div class="p-fluid p-float-label">
                    <p-dropdown
                      styleClass="w-full"
                      formControlName="currency"
                      [classList]="'w-100'"
                      [appendTo]="'body'"
                      [options]="[{label: 'PLN', value: 'PLN'}, {label: 'EUR', value: 'EUR'}]"
                      [filter]="true"
                      [filterFields]="['label']"
                      [dataKey]="'value'"
                      [optionLabel]="'label'"
                      [showClear]="true"
                      [checkmark]="true"
                      [placeholder]="('common.choose_or_search'|translate)"
                    ></p-dropdown>
                    <label>{{ 'common.currency'|translate }} <span class="text-danger">*</span></label>
                    <div *ngIf="form.get('currency').invalid && form.get('currency').touched" class="app-form-error">
                      <p>{{ form.get('currency')['errorMessage'] }}</p>
                      <p *ngIf="form.get('currency').errors?.custom">{{ form.get('currency').errors.custom }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 md:w-auto md:col px-0 py-2 md:px-2">
                  <div class="p-fluid p-float-label">
                    <input type="text" formControlName="price" pInputText/>
                    <label>{{ 'common.price'|translate }} <span class="text-danger">*</span></label>
                    <div *ngIf="form.get('price').invalid && form.get('price').touched" class="app-form-error">
                      <p>{{ form.get('price')['errorMessage'] }}</p>
                      <p *ngIf="form.get('price').errors?.custom">{{ form.get('price').errors.custom }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 md:w-auto md:col-fixed py-2">
                  <p-panel styleClass="nett-gross-toggle">
                    <div class="grid grid-nogutter align-items-center">
                      <div class="col pr-5">
                        <label>{{ 'common.value'|translate }} <span
                          class="text-danger">*</span></label>
                      </div>
                      <div class="col p-0 p-fluid">
                        <p-selectButton
                          formControlName="priceValue"
                          [options]="[{label: 'Brutto', value: 'GROSS'}, {label: 'Netto', value: 'NET'}]"
                          optionLabel="label"
                          optionValue="value"/>
                        <div *ngIf="form.get('priceValue').invalid && form.get('priceValue').touched" class="app-form-error">
                          <p>{{ form.get('priceValue')['errorMessage'] }}</p>
                          <p *ngIf="form.get('priceValue').errors?.custom">{{ form.get('priceValue').errors.custom }}</p>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="border-1 surface-border border-round mb-4">
              <span
                class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'shared.modal.modal_service.add_event.location'|translate }}&nbsp;<span
                class="text-danger">*</span></span>
              <div class="grid p-fluid p-2">
                <div class="col-12 mb-2 ">
                  <p-autoComplete
                    formControlName="address"
                    [placeholder]="'shared.modal.modal_service.add_event.search'| translate"
                    [suggestions]="searchResults"
                    (completeMethod)="searchPlaces($event)"
                    (onSelect)="selectPlace($event)"
                    [minLength]="2"
                    [delay]="500"
                    [optionLabel]="'label'"
                    [optionValue]="getAddrOptionValue"
                    [forceSelection]="true"
                    [required]="true"
                  >
                    <ng-template let-result pTemplate="item">
                      <div class="text-white">{{ result.label }}</div>
                    </ng-template>
                  </p-autoComplete>
                  <div *ngIf="form.get('address').invalid && form.get('address').touched" class="app-form-error">
                    <p>{{ form.get('address')['errorMessage'] }}</p>
                    <p *ngIf="form.get('address').errors?.custom">{{ form.get('address').errors.custom }}</p>
                  </div>
                </div>
                <div class="col-12">
                  <app-map *ngIf="activeTab === 'tier'"></app-map>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="border-1 surface-border border-round mb-4">
              <span
                class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'common.options'|translate }}</span>
              <div class="grid pt-3 p-2">
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                    <div class="col">
                      <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.invoice'|translate }} <span
                        class="text-danger">*</span></label>
                    </div>
                    <div class="col p-fluid">
                      <p-selectButton
                        formControlName="invoiceVat"
                        [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                        optionLabel="label"
                        optionValue="value"/>
                      <div *ngIf="form.get('invoiceVat').invalid && form.get('invoiceVat').touched" class="app-form-error">
                        <p>{{ form.get('invoiceVat')['errorMessage'] }}</p>
                        <p *ngIf="form.get('invoiceVat').errors?.custom">{{ form.get('invoiceVat').errors.custom }}</p>
                      </div>
                    </div>
                  </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                    <div class="col">
                      <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.invoice_margin'|translate }} <span
                        class="text-danger">*</span></label>
                    </div>
                    <div class="col p-fluid">
                      <p-selectButton
                        formControlName="invoiceVatMargin"
                        [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                        optionLabel="label"
                        optionValue="value"/>
                      <div *ngIf="form.get('invoiceVatMargin').invalid && form.get('invoiceVatMargin').touched" class="app-form-error">
                        <p>{{ form.get('invoiceVatMargin')['errorMessage'] }}</p>
                        <p *ngIf="form.get('invoiceVatMargin').errors?.custom">{{ form.get('invoiceVatMargin').errors.custom }}</p>
                      </div>
                    </div>
                  </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                    <div class="col">
                      <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.price_to_negotiation'|translate }}
                        <span class="text-danger">*</span></label>
                    </div>
                    <div class="col p-fluid">
                      <p-selectButton
                        formControlName="toNegotiation"
                        [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                        optionLabel="label"
                        optionValue="value"/>
                      <div *ngIf="form.get('toNegotiation').invalid && form.get('toNegotiation').touched" class="app-form-error">
                        <p>{{ form.get('toNegotiation')['errorMessage'] }}</p>
                        <p *ngIf="form.get('toNegotiation').errors?.custom">{{ form.get('toNegotiation').errors.custom }}</p>
                      </div>
                    </div>
                  </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                    <div class="col">
                      <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.leasing_assignment'|translate }}</label>
                    </div>
                    <div class="col p-fluid">
                      <p-selectButton
                        #leasingAssignment
                        [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                        optionLabel="label"
                        optionValue="value">
                      </p-selectButton>
                    </div>
                  </div>
                  </p-panel>
                </div>
                <div *ngIf="leasingAssignment.value" class="col-12">
                  <span
                    class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'shared.modal.modal_service.add_vehicle_advertisement.leasing_assignment'|translate }}</span>
                  <div class="grid mt-3">
                    <div class="col-12 md:col-6">
                      <div class="p-fluid p-float-label">
                        <input type="text" formControlName="leasingInitialFee" pInputText/>
                        <label>{{ 'common.initial_fee'|translate }} <span class="text-danger">*</span></label>
                        <div *ngIf="form.get('leasingInitialFee').invalid && form.get('leasingInitialFee').touched" class="app-form-error">
                          <p>{{ form.get('leasingInitialFee')['errorMessage'] }}</p>
                          <p *ngIf="form.get('leasingInitialFee').errors?.custom">{{ form.get('leasingInitialFee').errors.custom }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="p-fluid p-float-label">
                        <input type="text" formControlName="leasingMonthlyInstallment" pInputText/>
                        <label>{{ 'common.monthly_leasing_installment'|translate }} <span class="text-danger">*</span></label>
                        <div *ngIf="form.get('leasingMonthlyInstallment').invalid && form.get('leasingMonthlyInstallment').touched" class="app-form-error">
                          <p>{{ form.get('leasingMonthlyInstallment')['errorMessage'] }}</p>
                          <p *ngIf="form.get('leasingMonthlyInstallment').errors?.custom">{{ form.get('leasingMonthlyInstallment').errors.custom }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="p-fluid p-float-label">
                        <input type="text" formControlName="leasingRemainingInstallments" pInputText/>
                        <label>{{ 'common.remaining_installments'|translate }} <span class="text-danger">*</span></label>
                        <div *ngIf="form.get('leasingRemainingInstallments').invalid && form.get('leasingRemainingInstallments').touched" class="app-form-error">
                          <p>{{ form.get('leasingRemainingInstallments')['errorMessage'] }}</p>
                          <p *ngIf="form.get('leasingRemainingInstallments').errors?.custom">{{ form.get('leasingRemainingInstallments').errors.custom }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="p-fluid p-float-label">
                        <input type="text" formControlName="leasingBuyoutValue" pInputText/>
                        <label
                          >{{ 'common.buyout_value' | translate }}&nbsp;<span
                          class="text-danger">*</span></label>
                        <div *ngIf="form.get('leasingBuyoutValue').invalid && form.get('leasingBuyoutValue').touched" class="app-form-error">
                          <p>{{ form.get('leasingBuyoutValue')['errorMessage'] }}</p>
                          <p *ngIf="form.get('leasingBuyoutValue').errors?.custom">{{ form.get('leasingBuyoutValue').errors.custom }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="border-1 surface-border border-round mb-4">
              <span
                class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'common.about_vehicle'|translate }}</span>
              <div class="grid p-2">
                <div class="col-12 md:col-6">
                  <div class="p-fluid p-float-label">
                    <input type="text" formControlName="vin" pInputText/>
                    <label>{{ 'common.vin'|translate }} <span class="text-danger">*</span></label>
                    <div *ngIf="form.get('vin').invalid && form.get('vin').touched" class="app-form-error">
                      <p>{{ form.get('vin')['errorMessage'] }}</p>
                      <p *ngIf="form.get('vin').errors?.custom">{{ form.get('vin').errors.custom }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 md:col-6">
                  <div class="p-fluid p-float-label">
                    <input type="text" formControlName="mileage" pInputText/>
                    <label>{{ 'common.mileage'|translate }} <span class="text-danger">*</span></label>
                    <div *ngIf="form.get('mileage').invalid && form.get('mileage').touched" class="app-form-error">
                      <p>{{ form.get('mileage')['errorMessage'] }}</p>
                      <p *ngIf="form.get('mileage').errors?.custom">{{ form.get('mileage').errors.custom }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid p-2">
                <div class="col-12 md:col-6">
                  <div class="p-fluid p-float-label">
                    <input type="text" formControlName="registrationPlate" pInputText/>
                    <label>{{ 'common.registration_plate'|translate }} <span class="text-danger">*</span></label>
                    <div *ngIf="form.get('registrationPlate').invalid && form.get('registrationPlate').touched" class="app-form-error">
                      <p>{{ form.get('registrationPlate')['errorMessage'] }}</p>
                      <p *ngIf="form.get('registrationPlate').errors?.custom">{{ form.get('registrationPlate').errors.custom }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 md:col-6">
                  <div class="p-fluid p-float-label">
                    <p-calendar inputId="firstRegistrationInCountry"
                                formControlName="firstRegistrationInCountry"
                                [styleClass]="'registration-date'"
                    ></p-calendar>
                    <label
                      [for]="'firstRegistrationInCountry'">{{ 'shared.modal.modal_service.add_vehicle_advertisement.first_registration_date' | translate }}&nbsp;<span
                      class="text-danger">*</span></label>
                    <div *ngIf="form.get('firstRegistrationInCountry').invalid && form.get('firstRegistrationInCountry').touched" class="app-form-error">
                      <p>{{ form.get('firstRegistrationInCountry')['errorMessage'] }}</p>
                      <p *ngIf="form.get('firstRegistrationInCountry').errors?.custom">{{ form.get('firstRegistrationInCountry').errors.custom }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid p-2">
                <div class="col-12 md:col-6">
                  <div class="p-fluid p-float-label">
                    <p-dropdown
                      styleClass="w-full"
                      optionLabel="label"
                      formControlName="currentColour"
                      [appendTo]="'body'"
                      [options]="colours"
                      [filter]="true"
                      [filterFields]="['label']"
                      [showClear]="true"
                      [checkmark]="true"
                      [placeholder]="('common.choose_or_search'|translate)"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div class="text-white flex align-items-center gap-2" *ngIf="form.get('currentColour').value">
                          {{ form.get('currentColour').value.label }}
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <label>{{ 'common.colour'|translate }} <span class="text-danger">*</span></label>
                    <div *ngIf="form.get('currentColour').invalid && form.get('currentColour').touched" class="app-form-error">
                      <p>{{ form.get('currentColour')['errorMessage'] }}</p>
                      <p *ngIf="form.get('currentColour').errors?.custom">{{ form.get('currentColour').errors.custom }}</p>
                    </div>
                  </div>
                  <div class="p-fluid p-float-label mt-4" *ngIf="form.get('originalPaintDiffersCurrent').value">
                    <p-dropdown
                      styleClass="w-full"
                      optionLabel="label"
                      formControlName="originalColour"
                      [appendTo]="'body'"
                      [options]="colours"
                      [filter]="true"
                      [filterFields]="['label']"
                      [showClear]="true"
                      [checkmark]="true"
                      [placeholder]="('common.choose_or_search'|translate)"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div class="text-white flex align-items-center gap-2" *ngIf="form.get('originalColour').value">
                          {{ form.get('originalColour').value.label }}
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <label>{{ 'common.original_colour'|translate }} <span class="text-danger">*</span></label>
                    <div *ngIf="form.get('originalColour').invalid && form.get('originalColour').touched" class="app-form-error">
                      <p>{{ form.get('originalColour')['errorMessage'] }}</p>
                      <p *ngIf="form.get('originalColour').errors?.custom">{{ form.get('originalColour').errors.custom }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                      <div class="col-12 p-fluid">
                        <div class="p-field flex gap-5 align-items-center">
                          <p-checkbox
                            formControlName="originalPaintDiffersCurrent"
                            [binary]="true"
                            [inputId]="'original_colour_differs_current'"
                          ></p-checkbox>
                          <label [for]="'original_colour_differs_current'">{{ 'common.original_colour_differs_current'|translate }}</label>
                        </div>
                      </div>
                      <div class="col-12 p-fluid">
                        <div class="p-field flex gap-5 align-items-center">
                          <p-checkbox
                            formControlName="hasPaintCoverage"
                            [binary]="true"
                            [inputId]="'has_paint_coverage'"
                          ></p-checkbox>
                          <label [for]="'has_paint_coverage'">{{ 'common.has_paint_coverage'|translate }}</label>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6" *ngIf="form.get('hasPaintCoverage').value">
                  <p-panel>
                    <div class="grid align-items-center">
                      <div class="col">
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.ppf_wrap_coverage'|translate }} <span
                          class="text-danger">*</span></label>
                      </div>
                      <div class="col p-fluid">
                        <p-selectButton
                          formControlName="hasPpfWrapCoverage"
                          [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                          optionLabel="label"
                          optionValue="value">
                        </p-selectButton>
                        <div *ngIf="form.get('hasPpfWrapCoverage').invalid && form.get('hasPpfWrapCoverage').touched" class="app-form-error">
                          <p>{{ form.get('hasPpfWrapCoverage')['errorMessage'] }}</p>
                          <p *ngIf="form.get('hasPpfWrapCoverage').errors?.custom">{{ form.get('hasPpfWrapCoverage').errors.custom }}</p>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6" *ngIf="form.get('hasPaintCoverage').value">
                  <p-panel>
                    <div class="grid align-items-center">
                      <div class="col">
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.other_wrap_coverage'|translate }} <span
                          class="text-danger">*</span></label>
                      </div>
                      <div class="col p-fluid">
                        <p-selectButton
                          formControlName="hasOtherWrapCoverage"
                          [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                          optionLabel="label"
                          optionValue="value">
                        </p-selectButton>
                        <div *ngIf="form.get('hasOtherWrapCoverage').invalid && form.get('hasOtherWrapCoverage').touched" class="app-form-error">
                          <p>{{ form.get('hasOtherWrapCoverage')['errorMessage'] }}</p>
                          <p *ngIf="form.get('hasOtherWrapCoverage').errors?.custom">{{ form.get('hasOtherWrapCoverage').errors.custom }}</p>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6" *ngIf="form.get('hasPaintCoverage').value">
                  <p-panel>
                    <div class="grid align-items-center">
                      <div class="col">
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.other_paint_coverage'|translate }} <span
                          class="text-danger">*</span></label>
                      </div>
                      <div class="col p-fluid">
                        <p-selectButton
                          formControlName="hasOtherPaintCoverage"
                          [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                          optionLabel="label"
                          optionValue="value">
                        </p-selectButton>
                        <div *ngIf="form.get('hasOtherPaintCoverage').invalid && form.get('hasOtherPaintCoverage').touched" class="app-form-error">
                          <p>{{ form.get('hasOtherPaintCoverage')['errorMessage'] }}</p>
                          <p *ngIf="form.get('hasOtherPaintCoverage').errors?.custom">{{ form.get('hasOtherPaintCoverage').errors.custom }}</p>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6" *ngIf="form.get('hasPaintCoverage').value">
                  <p-panel>
                    <div class="grid align-items-center">
                      <div class="col">
                        <div class="p-fluid p-float-label">
                          <textarea formControlName="paintCoverageDescription"
                                    pInputTextarea
                                    [autoResize]="true"
                          ></textarea>
                          <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.paint_coverage_description'|translate }}</label>
                          <div *ngIf="form.get('paintCoverageDescription').invalid && form.get('paintCoverageDescription').touched" class="app-form-error">
                            <p>{{ form.get('paintCoverageDescription')['errorMessage'] }}</p>
                            <p *ngIf="form.get('paintCoverageDescription').errors?.custom">{{ form.get('paintCoverageDescription').errors.custom }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                      <div class="col">
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.imported'|translate }} <span
                          class="text-danger">*</span></label>
                      </div>
                      <div class="col p-fluid">
                        <p-selectButton
                          formControlName="imported"
                          [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                          optionLabel="label"
                          optionValue="value">
                        </p-selectButton>
                        <div *ngIf="form.get('imported').invalid && form.get('imported').touched" class="app-form-error">
                          <p>{{ form.get('imported')['errorMessage'] }}</p>
                          <p *ngIf="form.get('imported').errors?.custom">{{ form.get('imported').errors.custom }}</p>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                      <div class="col">
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.accident_free'|translate }} <span
                          class="text-danger">*</span></label>
                      </div>
                      <div class="col p-fluid">
                        <p-selectButton
                          formControlName="accidentFree"
                          [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                          optionLabel="label"
                          optionValue="value"/>
                        <div *ngIf="form.get('accidentFree').invalid && form.get('accidentFree').touched" class="app-form-error">
                          <p>{{ form.get('accidentFree')['errorMessage'] }}</p>
                          <p *ngIf="form.get('accidentFree').errors?.custom">{{ form.get('accidentFree').errors.custom }}</p>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                    <div class="col">
                      <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.damaged'|translate }} <span
                        class="text-danger">*</span></label>
                    </div>
                    <div class="col p-fluid">
                      <p-selectButton
                        formControlName="damaged"
                        [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                        optionLabel="label"
                        optionValue="value">
                      </p-selectButton>
                      <div *ngIf="form.get('damaged').invalid && form.get('damaged').touched" class="app-form-error">
                        <p>{{ form.get('damaged')['errorMessage'] }}</p>
                        <p *ngIf="form.get('damaged').errors?.custom">{{ form.get('damaged').errors.custom }}</p>
                      </div>
                    </div>
                  </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                    <div class="col">
                      <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.right_side_steering_wheel'|translate }}
                        <span class="text-danger">*</span></label>
                    </div>
                    <div class="col p-fluid">
                      <p-selectButton
                        formControlName="rightSideSteeringWheel"
                        [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                        optionLabel="label"
                        optionValue="value">
                      </p-selectButton>
                      <div *ngIf="form.get('rightSideSteeringWheel').invalid && form.get('rightSideSteeringWheel').touched" class="app-form-error">
                        <p>{{ form.get('rightSideSteeringWheel')['errorMessage'] }}</p>
                        <p *ngIf="form.get('rightSideSteeringWheel').errors?.custom">{{ form.get('rightSideSteeringWheel').errors.custom }}</p>
                      </div>
                    </div>
                  </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                    <div class="col">
                      <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.registered_in_current_country'|translate }}
                        <span class="text-danger">*</span></label>
                    </div>
                    <div class="col p-fluid">
                      <p-selectButton
                        formControlName="registeredInCountry"
                        [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                        optionLabel="label"
                        optionValue="value">
                      </p-selectButton>
                      <div *ngIf="form.get('registeredInCountry').invalid && form.get('registeredInCountry').touched" class="app-form-error">
                        <p>{{ form.get('registeredInCountry')['errorMessage'] }}</p>
                        <p *ngIf="form.get('registeredInCountry').errors?.custom">{{ form.get('registeredInCountry').errors.custom }}</p>
                      </div>
                    </div>
                  </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                    <div class="col">
                      <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.aso_service'|translate }} <span
                        class="text-danger">*</span></label>
                    </div>
                    <div class="col p-fluid">
                      <p-selectButton
                        formControlName="asoService"
                        [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                        optionLabel="label"
                        optionValue="value">
                      </p-selectButton>
                      <div *ngIf="form.get('asoService').invalid && form.get('asoService').touched" class="app-form-error">
                        <p>{{ form.get('asoService')['errorMessage'] }}</p>
                        <p *ngIf="form.get('asoService').errors?.custom">{{ form.get('asoService').errors.custom }}</p>
                      </div>
                    </div>
                  </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                    <div class="col">
                      <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.registered_as_antique'|translate }}
                        <span class="text-danger">*</span></label>
                    </div>
                    <div class="col p-fluid">
                      <p-selectButton
                        formControlName="registeredAsAntique"
                        [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                        optionLabel="label"
                        optionValue="value">
                      </p-selectButton>
                      <div *ngIf="form.get('registeredAsAntique').invalid && form.get('registeredAsAntique').touched" class="app-form-error">
                        <p>{{ form.get('registeredAsAntique')['errorMessage'] }}</p>
                        <p *ngIf="form.get('registeredAsAntique').errors?.custom">{{ form.get('registeredAsAntique').errors.custom }}</p>
                      </div>
                    </div>
                  </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                    <div class="col">
                      <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.mechanical_tuning'|translate }} <span
                        class="text-danger">*</span></label>
                    </div>
                    <div class="col p-fluid">
                      <p-selectButton
                        formControlName="mechanicalTuning"
                        [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                        optionLabel="label"
                        optionValue="value">
                      </p-selectButton>
                      <div *ngIf="form.get('mechanicalTuning').invalid && form.get('mechanicalTuning').touched" class="app-form-error">
                        <p>{{ form.get('mechanicalTuning')['errorMessage'] }}</p>
                        <p *ngIf="form.get('mechanicalTuning').errors?.custom">{{ form.get('mechanicalTuning').errors.custom }}</p>
                      </div>
                    </div>
                  </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                    <div class="col">
                      <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.visual_tuning'|translate }}
                        <span class="text-danger">*</span></label>
                    </div>
                    <div class="col p-fluid">
                      <p-selectButton
                        formControlName="visualTuning"
                        [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                        optionLabel="label"
                        optionValue="value">
                      </p-selectButton>
                      <div *ngIf="form.get('visualTuning').invalid && form.get('visualTuning').touched" class="app-form-error">
                        <p>{{ form.get('visualTuning')['errorMessage'] }}</p>
                        <p *ngIf="form.get('visualTuning').errors?.custom">{{ form.get('visualTuning').errors.custom }}</p>
                      </div>
                    </div>
                  </div>
                  </p-panel>
                </div>
                <div class="col-12 md:col-6">
                  <p-panel>
                    <div class="grid align-items-center">
                    <div class="col">
                      <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.truck_approval'|translate }} <span
                        class="text-danger">*</span></label>
                    </div>
                    <div class="col p-fluid">
                      <p-selectButton
                        formControlName="truckApproval"
                        [options]="[{label: 'common.yes'|translate, value: true}, {label: 'common.no'|translate, value: false}]"
                        optionLabel="label"
                        optionValue="value">
                      </p-selectButton>
                      <div *ngIf="form.get('truckApproval').invalid && form.get('truckApproval').touched" class="app-form-error">
                        <p>{{ form.get('truckApproval')['errorMessage'] }}</p>
                        <p *ngIf="form.get('truckApproval').errors?.custom">{{ form.get('truckApproval').errors.custom }}</p>
                      </div>
                    </div>
                  </div>
                  </p-panel>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="border-1 surface-border border-round mb-4">
              <span
                class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'common.equipment'|translate }}</span>
              <div class="grid grid-nogutter p-3 px-1">
                <ng-container *ngFor="let equipmentCategory of equipmentCategoryCollection">
                  <div class="col-12 py-2">
                    <p-fieldset [legend]="equipmentCategory.name" [toggleable]="true">
                      <div class="grid">
                        <ng-container *ngFor="let equipment of equipmentCategory.equipment; let i = index;">
                          <div class="col-12 md:col-6">
                            <div class="p-field flex gap-5 align-items-center">
                              <p-checkbox
                                (onChange)="equipment.selected = $event.checked"
                                [binary]="true"
                                [inputId]="'equipment' + i"
                              ></p-checkbox>
                              <label [for]="'equipment' + i">{{ equipment.name }}</label>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </p-fieldset>
                  </div>
                </ng-container>

              </div>
            </div>
          </div>
        </div>
        <!--        <div-->
        <!--          class="flex flex-column lg:flex-row gap-3 w-full h-full"-->
        <!--        >-->
        <!--          <div class="w-full">-->
        <!--            <div-->
        <!--              style="min-height: 400px"-->
        <!--              class="surface-ground shadow-3 p-0 border-round-md relative"-->
        <!--            >-->
        <!--              <div-->
        <!--                class="text-white text-xl py-3 px-2 bg-blue mb-3"-->
        <!--              >-->
        <!--                <div-->
        <!--                  class="flex px-3 align-items-center justify-content-between"-->
        <!--                >-->
        <!--                  <div>-->
        <!--                    <h1-->
        <!--                      class="inline text-white pt-5 text-3xl"-->
        <!--                    >-->
        <!--                      BASIC-->
        <!--                    </h1>-->
        <!--                  </div>-->
        <!--                  <div>-->
        <!--                    <h1-->
        <!--                      class="inline text-white pt-5 text-3xl"-->
        <!--                    >-->
        <!--                      $5-->
        <!--                    </h1>-->
        <!--                    <span>/m</span>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="text-base p-2">-->
        <!--                <div class="w-full h-3rem grid">-->
        <!--                  <div class="col-3">-->
        <!--                    <i class="pi pi-fw pi-check"></i>-->
        <!--                  </div>-->
        <!--                  <div class="col-9">Responsive</div>-->
        <!--                </div>-->
        <!--                <div class="w-full h-3rem grid">-->
        <!--                  <div class="col-3">-->
        <!--                    <i class="pi pi-fw pi-check"></i>-->
        <!--                  </div>-->
        <!--                  <div class="col-9">Push Messages</div>-->
        <!--                </div>-->
        <!--                <div-->
        <!--                  class="col-12 absolute w-auto"-->
        <!--                  style="-->
        <!--                                            bottom: 15px;-->
        <!--                                            right: 10px;-->
        <!--                                            left: 0px;-->
        <!--                                        "-->
        <!--                >-->
        <!--                  <button-->
        <!--                    pButton-->
        <!--                    pRipple-->
        <!--                    class="w-full"-->
        <!--                    type="button"-->
        <!--                    label="BASIC"-->
        <!--                    (click)="selectTier('basic')"-->
        <!--                  ></button>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="w-full">-->
        <!--            <div style="min-height: 400px" class="p-0 relative">-->
        <!--              <div-->
        <!--                style="min-height: 400px"-->
        <!--                class="surface-ground shadow-3 border-round-md p-0 relative"-->
        <!--              >-->
        <!--                <div-->
        <!--                  class="text-white text-xl py-3 px-2 bg-pink-500 mb-3"-->
        <!--                >-->
        <!--                  <div-->
        <!--                    class="flex px-3 align-items-center justify-content-between"-->
        <!--                  >-->
        <!--                    <div>-->
        <!--                      <h1-->
        <!--                        class="inline text-white pt-5 text-3xl"-->
        <!--                      >-->
        <!--                        PRO-->
        <!--                      </h1>-->
        <!--                    </div>-->
        <!--                    <div>-->
        <!--                      <h1-->
        <!--                        class="inline text-white pt-5 text-3xl"-->
        <!--                      >-->
        <!--                        $25-->
        <!--                      </h1>-->
        <!--                      <span>/m</span>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="text-base p-2">-->
        <!--                  <div class="w-full h-3rem grid">-->
        <!--                    <div class="col-3">-->
        <!--                      <i-->
        <!--                        class="pi pi-fw pi-check"-->
        <!--                      ></i>-->
        <!--                    </div>-->
        <!--                    <div class="col-9">Responsive</div>-->
        <!--                  </div>-->
        <!--                  <div class="w-full h-3rem grid">-->
        <!--                    <div class="col-3">-->
        <!--                      <i-->
        <!--                        class="pi pi-fw pi-check"-->
        <!--                      ></i>-->
        <!--                    </div>-->
        <!--                    <div class="col-9">-->
        <!--                      Push Messages-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="w-full h-3rem grid">-->
        <!--                    <div class="col-3">-->
        <!--                      <i-->
        <!--                        class="pi pi-fw pi-check"-->
        <!--                      ></i>-->
        <!--                    </div>-->
        <!--                    <div class="col-9">-->
        <!--                      7/24 Support-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="w-full h-3rem grid">-->
        <!--                    <div class="col-3">-->
        <!--                      <i-->
        <!--                        class="pi pi-fw pi-check"-->
        <!--                      ></i>-->
        <!--                    </div>-->
        <!--                    <div class="col-9">-->
        <!--                      Free Shipping-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div-->
        <!--                    class="col-12 absolute w-auto"-->
        <!--                    style="-->
        <!--                                                bottom: 15px;-->
        <!--                                                right: 10px;-->
        <!--                                                left: 0px;-->
        <!--                                            "-->
        <!--                  >-->
        <!--                    <button-->
        <!--                      pButton-->
        <!--                      pRipple-->
        <!--                      class="w-full bg-pink-500"-->
        <!--                      type="button"-->
        <!--                      label="SELECT PRO"-->
        <!--                      (click)="selectTier('pro')"-->
        <!--                    ></button>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->

        <!--          <div class="w-full">-->
        <!--            <div-->
        <!--              class="surface-ground shadow-3 border-round-md p-0 relative"-->
        <!--              style="min-height: 400px"-->
        <!--            >-->
        <!--              <div-->
        <!--                class="text-white text-xl py-3 px-2 bg-bluegray-500 mb-3"-->
        <!--              >-->
        <!--                <div-->
        <!--                  class="flex px-3 align-items-center justify-content-between"-->
        <!--                >-->
        <!--                  <div>-->
        <!--                    <h1-->
        <!--                      class="inline text-white pt-5 text-3xl"-->
        <!--                    >-->
        <!--                      PRO+-->
        <!--                    </h1>-->
        <!--                  </div>-->
        <!--                  <div>-->
        <!--                    <h1-->
        <!--                      class="inline text-white pt-5 text-3xl"-->
        <!--                    >-->
        <!--                      $50-->
        <!--                    </h1>-->
        <!--                    <span>/m</span>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="text-base p-2">-->
        <!--                <div class="w-full h-3rem grid">-->
        <!--                  <div class="col-3">-->
        <!--                    <i class="pi pi-fw pi-check"></i>-->
        <!--                  </div>-->
        <!--                  <div class="col-9">Responsive</div>-->
        <!--                </div>-->
        <!--                <div class="w-full h-3rem grid">-->
        <!--                  <div class="col-3">-->
        <!--                    <i class="pi pi-fw pi-check"></i>-->
        <!--                  </div>-->
        <!--                  <div class="col-9">Push Messages</div>-->
        <!--                </div>-->
        <!--                <div class="w-full h-3rem grid">-->
        <!--                  <div class="col-3">-->
        <!--                    <i class="pi pi-fw pi-check"></i>-->
        <!--                  </div>-->
        <!--                  <div class="col-9">7/24 Support</div>-->
        <!--                </div>-->
        <!--                <div class="w-full h-3rem grid">-->
        <!--                  <div class="col-3">-->
        <!--                    <i class="pi pi-fw pi-check"></i>-->
        <!--                  </div>-->
        <!--                  <div class="col-9">Free Shipping</div>-->
        <!--                </div>-->
        <!--                <div class="w-full h-3rem grid">-->
        <!--                  <div class="col-3">-->
        <!--                    <i class="pi pi-fw pi-check"></i>-->
        <!--                  </div>-->
        <!--                  <div class="col-9">Unlimited BW</div>-->
        <!--                </div>-->
        <!--                <div class="w-full h-3rem grid">-->
        <!--                  <div class="col-3">-->
        <!--                    <i class="pi pi-fw pi-check"></i>-->
        <!--                  </div>-->
        <!--                  <div class="col-9">-->
        <!--                    Special Gift Cards-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div-->
        <!--                  class="col-12 absolute w-auto"-->
        <!--                  style="-->
        <!--                                            bottom: 15px;-->
        <!--                                            right: 10px;-->
        <!--                                            left: 0px;-->
        <!--                                        "-->
        <!--                >-->
        <!--                  <button-->
        <!--                    pButton-->
        <!--                    pRipple-->
        <!--                    class="w-full bg-bluegray-500"-->
        <!--                    type="button"-->
        <!--                    label="SELECT PRO+"-->
        <!--                    (click)="selectTier('pro-plus')"-->
        <!--                  ></button>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div
        class="hidden h-full p-0 step"
        [ngClass]="{ 'flex flex-grow-1': activeTab === 'payment' }"
      >
        <div class="grid grid-nogutter w-full h-full">
          <div class="lg:col-8 col-12 py-8 px-4">
            <div class="grid col w-full">
              <div
                class="col-1 lg:col-1 md:col-1"
                style="line-height: 2em"
              >
                <p-radioButton
                  name="group1"
                  value="Credit"
                  [(ngModel)]="radioButton1"
                  inputId="credit"
                >
                </p-radioButton>
              </div>
              <div class="col-11 lg:col-5 md:col-5">
                <div class="credits">
                  <img
                    src="assets/layout/images/extensions/asset-mastercard.svg"
                    alt="ultima-layout"
                  />
                  <img
                    src="assets/layout/images/extensions/asset-visa.svg"
                    alt="ultima-layout"
                  />
                  <img
                    src="assets/layout/images/extensions/asset-amex.svg"
                    alt="ultima-layout"
                  />
                </div>
              </div>
              <div
                class="col-1 lg:col-1 md:col-1"
                style="line-height: 2em"
              >
                <p-radioButton
                  name="group1"
                  value="Paypal"
                  [(ngModel)]="radioButton1"
                  inputId="paypal"
                >
                </p-radioButton>
              </div>
              <div class="col-11 lg:col-5 md:col-5">
                <div class="paypal">
                  <img
                    src="assets/layout/images/extensions/asset-paypal.svg"
                    alt="ultima-layout"
                  />
                </div>
              </div>
            </div>
            <div class="grid col">
              <div class="col-12">
                                    <span class="p-float-label">
                                        <input
                                          class="w-full pb-3"
                                          pInputText
                                          type="text"
                                        />
                                        <label>Card Holder Name </label>
                                    </span>
              </div>
            </div>
            <div class="grid col">
              <div class="col-6">
                                    <span class="p-float-label">
                                        <input
                                          class="w-full pb-3"
                                          pInputText
                                          type="text"
                                        />
                                        <label>Card No</label>
                                    </span>
              </div>
              <div class="col-3">
                                    <span class="p-float-label">
                                        <input
                                          class="w-full pb-3"
                                          pInputText
                                          type="text"
                                        />
                                        <label>Date</label>
                                    </span>
              </div>
              <div class="col-3">
                                    <span class="p-float-label">
                                        <input
                                          class="w-full pb-3"
                                          pInputText
                                          type="text"
                                        />
                                        <label>CCV</label>
                                    </span>
              </div>
            </div>
            <div class="m-3">
              <p-checkbox
                [(ngModel)]="isRelatedToExistingVehicle"
                [binary]="true"
                labelStyleClass="ml-2 text-base"
                label="Save credit card information for future usage"
              ></p-checkbox>
            </div>
          </div>
          <div
            class="lg:col-4 col-12 p-3 surface-300 border-left-1 surface-border text-base text-gray-600"
          >
            <div class="grid col">
              <div class="col-12">
                <h1 class="mt-3">ORDER SUMMARY</h1>
              </div>
            </div>
            <div
              class="hidden"
              [ngClass]="{ block: activeCard === '' }"
            >
              <div class="grid col">
                <div class="col-8">Tier -</div>
                <div class="col-4 font-bold text-right">
                  $0.00
                </div>
                <div class="col-8">VAT (%18)</div>
                <div class="col-4 font-bold text-right">
                  $0.00
                </div>
              </div>
              <div
                class="grid col border-top-1 surface-border py-3 px-0 mt-5"
              >
                <div class="col-8">Total</div>
                <div class="col-4 font-bold text-right">
                  $0.00
                </div>
                <div class="col-12">
                  Please select one tier.
                </div>
              </div>
            </div>
            <div
              class="hidden"
              [ngClass]="{ block: activeCard === 'basic' }"
            >
              <div class="grid col">
                <div class="col-8">Tier - Basic</div>
                <div class="col-4 font-bold text-right">
                  $5.00
                </div>
                <div class="col-8">VAT (%18)</div>
                <div class="col-4 font-bold text-right">
                  $0.90
                </div>
              </div>
              <div
                class="grid col border-top-1 surface-border py-3 px-0 mt-5"
              >
                <div class="col-8">Total</div>
                <div class="col-4 font-bold text-right">
                  $5.90
                </div>
                <div class="col-12">
                  <p-button
                    type="button"
                    label="CONFIRM ORDER"
                    styleClass="w-full my-7"
                  >
                  </p-button>
                </div>
              </div>
            </div>
            <div
              class="hidden"
              [ngClass]="{ block: activeCard === 'pro' }"
            >
              <div class="grid col">
                <div class="col-8">Tier - Pro</div>
                <div class="col-4 font-bold text-right">
                  $25.00
                </div>
                <div class="col-8">VAT (%18)</div>
                <div class="col-4 font-bold text-right">
                  $4.50
                </div>
              </div>
              <div
                class="grid col border-top-1 surface-border py-3 px-0 mt-5"
              >
                <div class="col-8">Total</div>
                <div class="col-4 font-bold text-right">
                  $29.50
                </div>
                <div class="col-12">
                  <p-button
                    type="button"
                    label="CONFIRM ORDER"
                    styleClass="w-full my-7"
                  >
                  </p-button>
                </div>
              </div>
            </div>
            <div
              class="hidden"
              [ngClass]="{ block: activeCard === 'pro-plus' }"
            >
              <div class="grid col">
                <div class="col-8">Tier - Pro+</div>
                <div class="col-4 font-bold text-right">
                  $50.00
                </div>
                <div class="col-8">VAT (%18)</div>
                <div class="col-4 font-bold text-right">
                  $9.00
                </div>
              </div>
              <div
                class="grid col border-top-1 surface-border py-3 px-0 mt-5"
              >
                <div class="col-8">Total</div>
                <div class="col-4 font-bold text-right">
                  $59.00
                </div>
                <div class="col-12">
                  <p-button
                    type="button"
                    label="CONFIRM ORDER"
                    styleClass="w-full my-7"
                  >
                  </p-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid proceed-button grid-nogutter w-full flex justify-content-center">
        <div class="col-12">
          <p-progressBar *ngIf="mediaProgress !== null" [styleClass]="'w-full'" [style]="{ height: '6px' }" [value]="mediaProgress" />
        </div>
        <div class="col-12 lg:col-6 p-3">
          <button
            *ngIf="activeTab !== 'payment'"
            pButton
            pRipple
            class="w-full p-button-rounded button-primary text-white"
            type="submit"
            [label]="'common.button.proceed'|translate"
            (click)="clickNext(nextStep(activeTab))"
            [disabled]="selectedProjectVM === null || (activeTab === 'tier' && isSubmitted && form.invalid)"
          ></button>
          <button
            *ngIf="activeTab === 'payment'"
            pButton
            pRipple
            [loading]="mediaProgress !== null"
            class="w-full p-button-rounded button-primary text-white"
            type="button"
            [label]="'common.button.create_advertisement'|translate"
            (click)="submit()"
            [disabled]="selectedProjectVM === null || (isSubmitted && form.invalid)"
          ></button>
        </div>

      </div>
    </div>
  </div>
</div>
